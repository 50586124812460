import React from "react";
import ReactDOM from "react-dom";
import "./styles/util.css";
import "./styles/public.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import store from "./redux/store";
import { Provider } from "react-redux";
import "antd/dist/reset.css";
import { ErrorBoundary } from "react-error-boundary";
import ErrorComponent from "./ui/ErrorComponent";

const handleReset = () => {
  if (localStorage.getItem("auth") === "telegram") {
    window.location.replace("/mini-dashboard");
  } else {
    window.location.replace("/dashboard");
  }
};

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ErrorBoundary FallbackComponent={ErrorComponent} onReset={handleReset}>
        <App />
      </ErrorBoundary>
    </Provider>
  </BrowserRouter>,

  document.getElementById("root"),
);
