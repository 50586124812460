import { Card, Checkbox, Modal, Rate } from "antd";
import rateIcon from "../asset/Icons/rate.gif";

// STYLES
import styles from "../styles/RatingModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../redux/profile/actions";
import { useState } from "react";
import { HiOutlineEmojiSad } from "react-icons/hi";
import { notify } from "../utility/Toastify/toastify";

// Dynamic data for questions and answers
const pagesContent = {
  ai: {
    title: "AI Assistant",
    questions: {
      1: "Why are you not satisfied with our AI Assistant?",
      2: "Why are you not satisfied with our AI Assistant?",
      3: "Why are you not fully satisfied with our AI Assistant?",
      4: "Why are you not fully satisfied with our AI Assistant?",
    },
    answers: {
      1: [
        "Limited to only one option and unable to choose among different signals.",
        "Unable to make independent decisions based on provided signals.",
        "Category selection and risk appetite settings are restrictive or unclear.",
        "Lacks adaptability or customization in responses.",
      ],
      3: [
        "Provides some useful suggestions, but lacks flexibility in choices.",
        "Limited decision-making options, though risk settings are somewhat helpful.",
        "Categories and risk appetite settings are useful but could be more precise.",
        "Some responses are helpful, but lacks personalization for different needs.",
      ],
    },
  },
  insight: {
    title: "Insights",
    questions: {
      1: "Why are you not satisfied with our Insight feature?",
      2: "Why are you not satisfied with our Insight feature?",
      3: "Why are you not fully satisfied with our Insight feature?",
      4: "Why are you not fully satisfied with our Insight feature?",
    },
    answers: {
      1: [
        "Unreliable or inaccurate signals and predictions.",
        "Confusing or limited strategy-building options.",
        "Inconsistent or hard-to-understand chart visualizations.",
        "Outdated, irrelevant, or unhelpful suggestions.",
      ],
      3: [
        "Some reliable signals and predictions, but not all.",
        "Some useful strategy-building options, but could be more flexible.",
        "Some informative chart visualizations, but lacking in clarity or customization.",
        "Some relevant and helpful suggestions, but not consistently useful.",
      ],
    },
  },
  alert: {
    title: "Alert System",
    questions: {
      1: "Why are you not satisfied with our Alert System?",
      2: "Why are you not satisfied with our Alert System?",
      3: "Why are you not fully satisfied with our Alert System?",
      4: "Why are you not fully satisfied with our Alert System?",
    },
    answers: {
      1: [
        "Limited strategy options for setting alerts.",
        "Difficult to customize alerts based on personal preferences.",
        "Watchlist functionality is unclear or hard to manage.",
        "Notifications are inconsistent or delayed.",
      ],
      3: [
        "Some useful alert strategies, but limited customization.",
        "Basic alert options are available, but could be more specific.",
        "Watchlist is somewhat helpful, but could be easier to manage.",
        "Notifications are mostly timely, but sometimes inconsistent.",
      ],
    },
  },
  bot: {
    title: "Bot",
    questions: {
      1: "Why are you not satisfied with our Bot feature?",
      2: "Why are you not satisfied with our Bot feature?",
      3: "Why are you not fully satisfied with our Bot feature?",
      4: "Why are you not fully satisfied with our Bot feature?",
    },
    answers: {
      1: [
        "Bot creation process is confusing or too complex.",
        "Backtesting results are unreliable or difficult to interpret.",
        "Bot performance tracking lacks clarity or depth.",
        "Limited control over bot settings and behavior.",
      ],
      3: [
        "Bot creation is somewhat user-friendly, but could be simpler.",
        "Backtesting results are useful, but not always accurate.",
        "Performance tracking provides some insights, but lacks depth.",
        "Control over bot settings is adequate but could be more flexible.",
      ],
    },
  },
  api: {
    title: "API",
    questions: {
      1: "Why are you not satisfied with our API feature?",
      2: "Why are you not satisfied with our API feature?",
      3: "Why are you not fully satisfied with our API feature?",
      4: "Why are you not fully satisfied with our API feature?",
    },
    answers: {
      1: [
        "Enterprise plan options are unclear or limited.",
        "API documentation lacks detail or is hard to follow.",
        "Integration with software is challenging or buggy.",
        "Limited support for customization in API requests.",
      ],
      3: [
        "Enterprise plan options are somewhat helpful, but not very flexible.",
        "API documentation is informative, but could use more examples.",
        "Integration process works, but has occasional issues.",
        "API customization options are available, but could be more extensive.",
      ],
    },
  },
  integration: {
    title: "Integration",
    questions: {
      1: "Why are you not satisfied with our Integration feature?",
      2: "Why are you not satisfied with our Integration feature?",
      3: "Why are you not fully satisfied with our Integration feature?",
      4: "Why are you not fully satisfied with our Integration feature?",
    },
    answers: {
      1: [
        "Limited exchange options for integration.",
        "Portfolio view lacks clarity or customization.",
        "Issues with connecting to simulated portfolio.",
        "Actual trading functionality is unreliable or difficult to use.",
      ],
      3: [
        "Some exchange options are available, but could support more.",
        "Portfolio view is somewhat clear, but could use more customization.",
        "Simulated portfolio connection works, but not seamlessly.",
        "Actual trading functionality is helpful, but occasionally unreliable.",
      ],
    },
  },
};

// page prop can be: "ai", "insight", "alert", "integration", "api", "bot"

function RatingModal({ show, handleShow, page }) {
  const {
    main: { userData },
    profile: { updateLoading },
  } = useSelector((state) => state);
  const [step, setStep] = useState(1);
  const [rate, setRate] = useState(0);
  const [reasons, setReasons] = useState([]);
  const dispatch = useDispatch();

  const closeHandler = () => {
    handleShow(false);
    dispatch(
      updateProfile(
        userData?.email,
        userData["custom:custom_username"],
        [`${page}_cancel_rate_date`],
        [new Date()],
        false,
      ),
    );
  };

  const submitData = (rate, reason = "") => {
    return dispatch(
      updateProfile(
        userData?.email,
        userData["custom:custom_username"],
        [`${page}_rating_date`, `${page}_rate`, `${page}_rate_reason`],
        [new Date(), rate, reason],
        false,
      ),
    );
  };

  const handleRating = (value) => {
    setRate(value);
    if (value === 5) {
      submitData(value);
      handleShow(false);
      setRate(0);
      return;
    }
    setStep(2);
  };

  const submitHandler = () => {
    if (!reasons.length) {
      notify("Please Select a Valid Option", "warning");
      return;
    }

    const reason = reasons.join(", ");
    submitData(rate, reason);
    handleShow(false);
    setStep(1);
    setRate(0);
  };

  const renderCheckboxGroup = (options) => (
    <Checkbox.Group
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "2rem 0 1rem",
      }}
      value={reasons}
      onChange={(values) => setReasons(values)}
    >
      {options.map((r) => (
        <Checkbox
          style={{ marginLeft: "0", marginBottom: "1rem" }}
          key={r}
          value={r}
        >
          {r}
        </Checkbox>
      ))}
    </Checkbox.Group>
  );

  const content = pagesContent[page];
  const reasonsKey = rate === 1 || rate === 2 ? 1 : 3;
  const question =
    rate === 1 || rate === 2
      ? `Why are you not satisfied with our ${content?.title} feature?`
      : `Why are you not fully satisfied with our ${content?.title} feature?`;

  return (
    <Modal
      width={550}
      centered
      open={show}
      footer={null}
      closable={!updateLoading}
      maskClosable={false}
      onCancel={closeHandler}
    >
      <Card style={{ margin: "1rem" }}>
        <div className={styles.container}>
          {step === 1 && (
            <>
              <h3 style={{ textAlign: "center" }}>
                Rate your experience with our {content?.title}.{" "}
                <img style={{ width: "25px" }} src={rateIcon} alt="rating" />
              </h3>
              <Rate value={rate} onChange={handleRating} />
            </>
          )}
          {step === 2 && (
            <div className={styles.stepContainer}>
              <h3 style={{ display: "flex", alignItems: "center" }}>
                <span>{question}</span>
                <HiOutlineEmojiSad
                  style={{ fontSize: "20px", marginLeft: ".2rem" }}
                />
              </h3>
              <div>{renderCheckboxGroup(content?.answers[reasonsKey])}</div>
              <div className={styles.btns}>
                <span className={styles.backBtn} onClick={() => setStep(1)}>
                  Back
                </span>
                <span className={styles.submitButton} onClick={submitHandler}>
                  Submit
                </span>
              </div>
            </div>
          )}
        </div>
      </Card>
    </Modal>
  );
}

export default RatingModal;
