import { useEffect } from "react";
import usePageTitle from "../hooks/usePageTitle";
import useGeoLocation from "../hooks/useGeolocation";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { isMobile, isTablet } from "react-device-detect";

function VerifyUserLocation() {
  usePageTitle("Verify location");
  const {
    location: { lat, lon },
    getLocation,
  } = useGeoLocation();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // const email = searchParams.get("email");
  // const username = searchParams.get("user");
  const token = searchParams.get("token");

  if (token) axios.defaults.headers.common["Authorization"] = token;

  useEffect(() => {
    localStorage.setItem("process", "verifyLocation");
    getLocation();
  }, []);

  useEffect(() => {
    if (!isMobile && !isTablet) {
      console.log("navigate");
      navigate(-1);
    }
  }, [isMobile]);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h3>
        Please enable location access in your browser to complete verification.
        Thank you!
      </h3>
    </div>
  );
}

export default VerifyUserLocation;
