import axios from "axios";
import { axiosErrorHandler } from "../../utility/action-helper";

const initialState = {
  suggestions: {},
  status: "",
  errorMessage: "",
};

export const suggestionReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "get_suggestions_request":
      return {
        ...state,
        status: "loading",
        errorMessage: "",
      };

    case "get_suggestions_success":
      return {
        ...state,
        status: "success",
        suggestions: payload,
      };

    case "get_suggestions_error":
      return {
        ...state,
        status: "error",
      };
    default:
      return state;
  }
};

export const getLiveSuggestion =
  (exchange_id, symbolName, do_backtest = false, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: "get_suggestions_request" });

    let data = {
      action: "inference",
      exchange_id,
      symbol: symbolName?.includes("USDT") ? symbolName : `${symbolName}/USDT`,
    };

    if (do_backtest) {
      data.do_backtest = "True";
    }

    // AUSTRALIA API
    // https://akqyv95sd7.execute-api.ap-southeast-2.amazonaws.com/dev/pivot

    // USA API
    // https://kw827865dl.execute-api.us-east-1.amazonaws.com/dev/pivot

    await axios
      .post(
        "https://akqyv95sd7.execute-api.ap-southeast-2.amazonaws.com/dev/pivot",
        data,
      )
      .then((res) => {
        console.log(res);
        dispatch({ type: "get_suggestions_success", payload: res?.data });
        // console.log(res);
      })
      .catch((err) => {
        console.log(err, axios.isAxiosError(err));
        // dispatch({
        //   type: "get_suggestions_error",
        //   payload: err?.response?.data?.message || "Something went wrong",
        // });
        axiosErrorHandler(
          axios.isAxiosError(err) || err,
          retryCount,
          dispatch,
          () =>
            getLiveSuggestion(
              exchange_id,
              symbolName,
              do_backtest,
              retryCount + 1,
            ),
          "get_suggestions_error",
          "user",
          "email",
          "inference",
        );
      });
  };
