import TradingViewWidget from "./chart-section/TradingViewWidget";
import SignalsSummary from "./chart-section/SignalsSummary";
import LiveSuggestionButton from "../../shared/live-suggestion/LiveSuggestionButton";

function InsightChartSection() {
  return (
    <>
      <LiveSuggestionButton />
      <TradingViewWidget />
      <SignalsSummary />
    </>
  );
}

export default InsightChartSection;
