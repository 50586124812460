import styles from "../../../styles/Components/trade-assistant/portfolio/VirtualPortfolioTableActionButtons.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getPortfolioData } from "../../../redux/trading-assistant/actions";
import { FiRefreshCcw } from "react-icons/fi";
import { Button, Dropdown, Menu } from "antd";
import React, { useState } from "react";
import { setMode, setStep } from "../../../redux/trade/actions";
import TradeModal from "../../../shared/trade-modal/TradeModal";
import LinkWithExchange from "../../../shared/link-with-exchange/LinkWithExchange";
import UnlinkFromExchange from "../../../shared/link-with-exchange/UnlinkFromExchange";
import { BiLink, BiUnlink } from "react-icons/bi";
import { MdKeyboardArrowDown } from "react-icons/md";
import AIAssistantServiceActivationModal from "../AIAssistantServiceActivationModal";
import { RiLockFill } from "react-icons/ri";

function VirtualPortfolioTableActionButtons() {
  const {
    main: { userData },
    tradingAssistant: { getPortfolioDataLoading, selectedPortfolio },
    profile: { profileData },
  } = useSelector((state) => state);

  const portflio = selectedPortfolio?.toLowerCase();

  const isFirstPortfolioConnected =
    profileData?.portfolio1_linked_exchange &&
    profileData?.portfolio1_linked_exchange !== "None";
  const isSecondPortfolioConnected =
    profileData?.portfolio2_linked_exchange &&
    profileData?.portfolio2_linked_exchange !== "None";

  const showLinkButton =
    (portflio === "primary" && !isFirstPortfolioConnected) ||
    (portflio === "secondary" && !isSecondPortfolioConnected);
  const showUnlinkButton =
    (portflio === "primary" && isFirstPortfolioConnected) ||
    (portflio === "secondary" && isSecondPortfolioConnected);
  const portfolioNumber = portflio === "primary" ? 1 : 2;
  const linkedExchange =
    profileData?.[`portfolio${portfolioNumber}_linked_exchange`];

  const [showTradeModal, setShowTradeModal] = useState(false);
  const [showServiceActivationModal, setShowServiceActivationModal] =
    useState(false);

  const isServiceActive = profileData?.myais_active === "True";

  const dispatch = useDispatch();

  const handleTrade = () => {
    if (!isServiceActive) {
      setShowServiceActivationModal(true);
      return;
    }

    setShowTradeModal(true);
    dispatch(setStep("1-c"));
    dispatch(setMode("ai-btn"));
  };

  const actionMenuItems = [
    {
      label: (
        <LinkWithExchange
          btnText={"Link with Exchange"}
          className={styles.linkBtn}
        />
      ),
      icon: <BiLink />,
      key: "1",
    },
  ];

  if (isFirstPortfolioConnected || isSecondPortfolioConnected) {
    actionMenuItems.push({
      label: <UnlinkFromExchange className={styles.linkBtn} />,
      icon: <BiUnlink />,
      key: "2",
    });
  }

  const actionMenu = (
    <Menu className={styles.actionMenu} items={[...actionMenuItems]} />
  );

  return (
    <>
      <div className={styles.container}>
        {showUnlinkButton && (
          <UnlinkFromExchange
            btnText={`Unlink portfolio ${portfolioNumber} from ${linkedExchange}`}
          />
        )}
        {showLinkButton && <LinkWithExchange btnText={"Link with Exchange"} />}
        <Dropdown overlay={actionMenu}>
          <Button className={styles.moreBtn}>
            More Actions <MdKeyboardArrowDown />
          </Button>
        </Dropdown>
        <Button onClick={handleTrade} className={styles.buySellBtn}>
          {!isServiceActive && <RiLockFill />} Buy / Sell
        </Button>
        <Button
          className={styles.refreshBtn}
          loading={getPortfolioDataLoading}
          onClick={() => {
            if (getPortfolioDataLoading) return;
            dispatch(
              getPortfolioData(
                userData?.["cognito:username"],
                userData?.["custom:custom_username"],
                selectedPortfolio?.toLowerCase() || "primary",
              ),
            );
          }}
        >
          {!getPortfolioDataLoading && <FiRefreshCcw />}
          <span className={styles.desktopVersion}>Refresh</span>
        </Button>
      </div>

      <TradeModal show={showTradeModal} setShow={setShowTradeModal} />

      <AIAssistantServiceActivationModal
        showActivationModal={showServiceActivationModal}
        setShowActivationModal={setShowServiceActivationModal}
      />
    </>
  );
}

export default VirtualPortfolioTableActionButtons;
