import { useEffect, useState } from "react";
import { notify } from "../utility/Toastify/toastify";
import {
  BsExclamationTriangleFill,
  BsQuestionCircle,
} from "react-icons/bs/index.js";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../redux/profile/actions";
import { LazyLoadImage } from "react-lazy-load-image-component";

// FUNCTIONS
import { suggestFeatures } from "../utility/helper";

// STYLES
import styles from "../styles/StartWizard.module.css";

// IMAGES
import logo from "../asset/Images/cryptocrispyLogo.png";
import answer from "../asset/Images/question.png";
import question from "../asset/Images/confus.png";

// ICONS
import insight from "../asset/Icons/insight.gif";
import assistant from "../asset/Icons/ai.gif";
import simulator from "../asset/Icons/simulator.gif";
import developer from "../asset/Icons/dev.gif";
import alert from "../asset/Icons/alert.gif";
import smile from "../asset/Icons/smile.gif";
import gift from "../asset/Icons/gift.gif";
import rocket from "../asset/Icons/rocket.gif";
import plan from "../asset/Icons/plan.gif";
import ExchangesModal from "./ExchangesModal";
import exchange from "../asset/Images/exchange.jpeg";
import risk1 from "../asset/Images/risk1.jpg";
import TopExchanges from "./start-wizard/TopExchanges";
import IntegrationAlert from "./start-wizard/IntegrationAlert";
import { getTrustedIps } from "../redux/exchange/actions";
import { FiSkipForward } from "react-icons/fi";
import { AiTwotoneFire } from "react-icons/ai";
import { HiRocketLaunch } from "react-icons/hi2";
import {
  FaBalanceScale,
  FaChartLine,
  FaCode,
  FaShieldAlt,
} from "react-icons/fa";

import warn from "../asset/Icons/warn.gif";
import { Modal } from "antd";
import ErrorComponent from "../components/errors/ErrorComponent";

const SkipAll = ({ handleClick }) => {
  return (
    <div className={styles.skipAll}>
      <FiSkipForward style={{ marginRight: ".2rem" }} />
      <span onClick={handleClick}>Skip All</span>
    </div>
  );
};

const StartWizard = function () {
  const [step, setStep] = useState(0);
  const [riskAppetite, setRiskAppetite] = useState("");
  const [showExchanges, setShowExchanges] = useState(false);
  const [showIntegrationAlert, setShowIntegrationAlert] = useState(false);
  const [isFirstReview, setIsFirstReview] = useState(true);
  const [showSkipModal, setShowSkipModal] = useState(false);
  const [answers, setAnswers] = useState({
    1: "",
    2: "",
    3: "",
    4: "",
  });

  const icons = {
    Insight: insight,
    "AI assistant": assistant,
    "Trade simulator": simulator,
    "Alert system": alert,
    "Developer API": developer,
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let {
    main: { userData },
    profile: { profileData },
    exchangeState: {
      integratedData,
      exchangesLoading,
      trustedIps,
      getTrustedIpsStatus,
      exchangesError,
    },
  } = useSelector((state) => state);

  const handleClick = (e) => {
    e.preventDefault();
    dispatch(
      updateProfile(
        userData?.email,
        userData["custom:custom_username"],
        ["suggested_features", "answer_date", "ai_assistant_risk_appetite"],
        [
          suggestFeatures(answers[1], answers[2], answers[3], answers[4]).join(
            ", ",
          ),
          new Date(),
          riskAppetite,
        ],
        false,
      ),
    );
    navigate("/ai-assistant/assistant");
    document.title = "CryptoCrispy App";
  };

  const handleAnswer = (n, e) => {
    const val = e.target.id;
    setAnswers((prevState) => ({
      ...prevState,
      [n]: val,
    }));
  };

  const changeRiskHandler = (e) => {
    if (e.target.closest("span")) {
      setRiskAppetite(e.target.id);
    }
  };

  const goToSecondStep = () => {
    if (exchangesLoading) return;
    if (!integratedData.length) {
      setShowIntegrationAlert(true);
      return;
    }
    setStep(1);
  };

  useEffect(() => {
    document.title = "Suggestion Features";
  }, []);

  // Change app title when the component unmount
  useEffect(() => {
    return () => (document.title = "CryptoCrispy App");
  }, []);

  // Show skip modal when arrive to third step as first time
  useEffect(() => {
    if (step === 2 && isFirstReview) {
      setShowSkipModal(true);
      setIsFirstReview(false);
    }
  }, [step, isFirstReview]);

  useEffect(() => {
    if (
      userData &&
      Object.keys(userData)?.length &&
      !trustedIps.length &&
      getTrustedIpsStatus !== "error" &&
      getTrustedIpsStatus !== "loading"
    ) {
      dispatch(getTrustedIps());
    }
  }, [dispatch, trustedIps, userData, getTrustedIpsStatus]);

  return (
    <div className={styles.container}>
      <LazyLoadImage src={logo} wrapperClassName={styles.logo} effect="blur" />
      {/*<img className={styles.logo} src={logo} alt="cryptocrispy" />*/}
      {exchangesError ? (
        <ErrorComponent />
      ) : (
        <>
          <div className={styles.head}>
            {step === 0 && (
              // <img
              //   className={styles.stepImg}
              //   src={exchange}
              //   alt="exchange"
              //   lang={"lazy"}
              // />
              <LazyLoadImage
                src={exchange}
                alt="exchange"
                effect="blur"
                height="200px"
                width="300px"
              />
            )}
            {step === 1 && (
              <LazyLoadImage
                src={risk1}
                alt="risk appetite"
                effect="blur"
                height="200px"
                width="300px"
              />
              // <img
              //   className={styles.stepImg}
              //   src={risk1}
              //   alt="risk appetite"
              //   lang={"lazy"}
              // />
            )}
            {step < 6 && step > 1 && (
              // <img
              //   className={styles.stepImg}
              //   src={question}
              //   alt="img"
              //   lang={"lazy"}
              // />
              <LazyLoadImage
                src={question}
                alt="question"
                effect="blur"
                height="200px"
                width="300px"
              />
            )}

            {step < 6 && step > 1 && (
              <p style={{ fontSize: "16px" }}>
                Let's see what features are suitable for you:
              </p>
            )}

            {step === 6 && (
              // <img
              //   className={styles.stepImg}
              //   src={answer}
              //   alt="result"
              //   loading={"lazy"}
              // />
              <LazyLoadImage
                src={answer}
                alt="answer"
                effect="blur"
                height="200px"
                width="300px"
              />
            )}
          </div>
          <div className={styles.content}>
            {step === 0 && (
              <div className={styles.stepContainer}>
                <div className={styles.questionBox}>
                  <p style={{ textAlign: "center" }}>
                    Integrate with an exchange, if you have any.
                  </p>
                  <TopExchanges showMore={setShowExchanges} />
                </div>
                <span className={styles.nextBtn} onClick={goToSecondStep}>
                  Next
                </span>
              </div>
            )}
            {step === 1 && (
              <div className={styles.stepContainer}>
                <div className={styles.questionBox}>
                  <p>
                    <BsQuestionCircle style={{ marginRight: ".5rem" }} />
                    What is your preferred risk appetite for signals?
                    <span className={styles.forceIcon}>*</span>
                  </p>
                  <div onClick={changeRiskHandler} className={styles.buttons}>
                    <span
                      title="Slow and Steady"
                      id="1"
                      className={`${styles.button} ${styles.riskBtn} ${
                        riskAppetite === "1" ? styles.active : ""
                      }`}
                    >
                      <FaShieldAlt className={styles.riskIcon} />
                      Very Cautious
                    </span>
                    <span
                      title="Safe and Sound"
                      id="2"
                      className={`${styles.button} ${styles.riskBtn} ${
                        riskAppetite === "2" ? styles.active : ""
                      }`}
                    >
                      <BsExclamationTriangleFill className={styles.riskIcon} />
                      Cautious
                    </span>
                    <span
                      title="Steady as She Goes"
                      id="3"
                      className={`${styles.button} ${styles.riskBtn} ${
                        riskAppetite === "3" ? styles.active : ""
                      }`}
                    >
                      <FaBalanceScale className={styles.riskIcon} />
                      Balanced
                    </span>
                    <span
                      title="High Wire Act"
                      id="4"
                      className={`${styles.button} ${styles.riskBtn} ${
                        riskAppetite === "4" ? styles.active : ""
                      }`}
                    >
                      <HiRocketLaunch className={styles.riskIcon} />
                      Adventurous
                    </span>
                    <span
                      title="All In"
                      id="5"
                      className={`${styles.button} ${styles.riskBtn} ${
                        riskAppetite === "5" ? styles.active : ""
                      }`}
                    >
                      <AiTwotoneFire className={styles.riskIcon} />
                      Very Adventurous
                    </span>
                  </div>
                </div>
                <div className={styles.handlers}>
                  <span className={styles.backBtn} onClick={() => setStep(0)}>
                    Back
                  </span>
                  <span
                    className={styles.nextBtn}
                    style={!riskAppetite ? { cursor: "not-allowed" } : null}
                    onClick={() => {
                      if (!riskAppetite) {
                        notify("Please select a valid option.", "warning");
                        return;
                      }
                      setStep(2);
                    }}
                  >
                    Next
                  </span>
                </div>
              </div>
            )}
            {step === 2 && (
              <div className={styles.stepContainer}>
                <div className={styles.questionBox}>
                  <p>
                    <BsQuestionCircle style={{ marginRight: ".5rem" }} />
                    Which option do you prefer for receiving crypto signals?
                    <span className={styles.label}>(optional)</span>
                  </p>
                  <div className={`${styles.buttons} ${styles.bigBtns}`}>
                    <span
                      onClick={(e) => handleAnswer(1, e)}
                      className={`${styles.button} ${
                        answers[1] === "sit in front" ? styles.active : ""
                      }`}
                      id="sit in front"
                    >
                      Actively monitor your screen every hour.
                    </span>
                    <span
                      onClick={(e) => handleAnswer(1, e)}
                      className={`${styles.button} ${
                        answers[1] === "alerted" ? styles.active : ""
                      }`}
                      id="alerted"
                    >
                      Receive alerts that eliminate the need for constant
                      monitoring.
                    </span>
                  </div>
                </div>
                <div className={styles.handlers}>
                  <span className={styles.backBtn} onClick={() => setStep(1)}>
                    Back
                  </span>
                  <div className={styles.nextBtns}>
                    <SkipAll handleClick={() => setStep(6)} />
                    <span className={styles.nextBtn} onClick={() => setStep(3)}>
                      Next / Skip
                    </span>
                  </div>
                </div>
              </div>
            )}
            {step === 3 && (
              <div className={styles.stepContainer}>
                <div className={styles.questionBox}>
                  <p>
                    <BsQuestionCircle style={{ marginRight: ".5rem" }} />
                    Do you prefer:
                    <span className={styles.label}>(optional)</span>
                  </p>
                  <div className={`${styles.buttons} ${styles.bigBtns}`}>
                    <span
                      onClick={(e) => handleAnswer(2, e)}
                      className={`${styles.button} ${
                        answers[2] === "dig" ? styles.active : ""
                      }`}
                      id="dig"
                    >
                      Manually exploring AI signals, charts, and indicators to
                      make informed trading decisions.
                    </span>
                    <span
                      onClick={(e) => handleAnswer(2, e)}
                      className={`${styles.button} ${
                        answers[2] === "no time for that" ? styles.active : ""
                      }`}
                      id="no time for that"
                    >
                      A quick, automated solution that provides trading
                      recommendations without the need for manual research.
                    </span>
                  </div>
                </div>
                <div className={styles.handlers}>
                  <span className={styles.backBtn} onClick={() => setStep(2)}>
                    Back
                  </span>
                  <div className={styles.nextBtns}>
                    <SkipAll handleClick={() => setStep(6)} />

                    <span className={styles.nextBtn} onClick={() => setStep(4)}>
                      Next / Skip
                    </span>
                  </div>
                </div>
              </div>
            )}
            {step === 4 && (
              <div className={styles.stepContainer}>
                <div className={styles.questionBox}>
                  <p>
                    <BsQuestionCircle style={{ marginRight: ".5rem" }} />
                    Are you a developer yourself or just a trader?
                    <span className={styles.label}>(optional)</span>
                  </p>
                  <div className={styles.buttons}>
                    <span
                      onClick={(e) => handleAnswer(3, e)}
                      className={`${styles.button} ${
                        answers[3] === "yes I am" ? styles.active : ""
                      }`}
                      id="yes I am"
                      style={{ color: "#800080" }}
                    >
                      <FaCode />
                      Developer
                    </span>
                    <span
                      onClick={(e) => handleAnswer(3, e)}
                      className={`${styles.button} ${
                        answers[3] === "Im not" ? styles.active : ""
                      }`}
                      id="Im not"
                      style={{ color: "#FFD700" }}
                    >
                      <FaChartLine />
                      Trader
                    </span>
                  </div>
                </div>

                <div className={styles.handlers}>
                  <span className={styles.backBtn} onClick={() => setStep(3)}>
                    Back
                  </span>
                  <div className={styles.nextBtns}>
                    <SkipAll handleClick={() => setStep(6)} />
                    <span className={styles.nextBtn} onClick={() => setStep(5)}>
                      Next / Skip
                    </span>
                  </div>
                </div>
              </div>
            )}
            {step === 5 && (
              <div className={styles.stepContainer}>
                <div className={styles.questionBox}>
                  <p>
                    <BsQuestionCircle style={{ marginRight: ".5rem" }} />
                    Would you like to:
                    <span className={styles.label}>(optional)</span>
                  </p>
                  <div className={`${styles.buttons} ${styles.bigBtns}`}>
                    <span
                      onClick={(e) => handleAnswer(4, e)}
                      className={`${styles.button} ${
                        answers[4] === "evaluate" ? styles.active : ""
                      }`}
                      id="evaluate"
                    >
                      Manually evaluate trade performance by checking yourself.
                    </span>
                    <span
                      onClick={(e) => handleAnswer(4, e)}
                      className={`${styles.button} ${
                        answers[4] === "rely on reports" ? styles.active : ""
                      }`}
                      id="rely on reports"
                    >
                      Analyze trade performance using our backtests and trade
                      simulator tools.
                    </span>
                  </div>
                </div>
                <div className={styles.handlers}>
                  <span className={styles.backBtn} onClick={() => setStep(4)}>
                    Back
                  </span>
                  <span className={styles.nextBtn} onClick={() => setStep(6)}>
                    Next / Skip
                  </span>
                </div>
              </div>
            )}
            {step === 6 && (
              <div className={styles.result}>
                <p>Based on your answers, we suggest the following features:</p>
                <ul className={styles.features}>
                  {suggestFeatures(
                    answers[1],
                    answers[2],
                    answers[3],
                    answers[4],
                  ).map((item, i) => (
                    <li
                      style={{
                        padding: ".5rem 0",
                        display: "flex",
                        alignItems: "center",
                      }}
                      key={i}
                    >
                      <LazyLoadImage
                        src={icons[item]}
                        alt={item}
                        width="30px"
                        height="30px"
                        effect="blur"
                      />
                      {/*<img*/}
                      {/*  style={{ width: "35px" }}*/}
                      {/*  src={icons[item]}*/}
                      {/*  alt={item}*/}
                      {/*/>*/}
                      <span style={{ marginLeft: ".5rem" }}>{item}</span>
                    </li>
                  ))}
                </ul>
                <div
                  className={styles.textContainer}
                  style={{ textAlign: "center" }}
                >
                  {!profileData?.suggested_features ? (
                    <p>
                      <LazyLoadImage
                        src={gift}
                        alt="gift"
                        width="35px"
                        height="35px"
                        effect="blur"
                        wrapperClassName={styles.icon}
                      />
                      {/*<img style={{ width: "40px" }} src={gift} alt="gift" />*/}
                      Enjoy our platform for free for 30 days.
                      <LazyLoadImage
                        src={gift}
                        alt="gift"
                        width="35px"
                        height="35px"
                        effect="blur"
                        wrapperClassName={styles.icon}
                      />
                      {/*<img style={{ width: "40px" }} src={gift} alt="gift" />*/}
                    </p>
                  ) : (
                    ""
                  )}

                  <p>
                    Get more gifts by{" "}
                    <a
                      style={{ color: "#0c4cfc", fontSize: "16px" }}
                      href="https://t.me/cryptocrispy_ai"
                      target="_blank"
                      rel="noreferrer"
                    >
                      following us
                    </a>{" "}
                    or inviting friends.
                    <LazyLoadImage
                      src={smile}
                      alt="smile"
                      width="25px"
                      height="25px"
                      effect="blur"
                      wrapperClassName={styles.icon}
                    />
                    {/*<img style={{ width: "25px" }} src={smile} alt="smile" />*/}
                  </p>
                  <p>
                    Upgrade to a plan anytime.
                    {/*<img style={{ width: "30px" }} src={plan} alt="plan" />*/}
                    <LazyLoadImage
                      src={plan}
                      alt="plan"
                      width="25px"
                      height="25px"
                      effect="blur"
                      wrapperClassName={styles.icon}
                    />
                  </p>
                  <p>
                    Start your free trial now!
                    <LazyLoadImage
                      src={rocket}
                      alt="rocket"
                      width="25px"
                      height="25px"
                      effect="blur"
                      wrapperClassName={styles.icon}
                    />
                    {/*<img style={{ width: "30px" }} src={rocket} alt="rocket" />*/}
                  </p>
                </div>
                <span className={styles.backBtn} onClick={() => setStep(5)}>
                  Back
                </span>
                <button
                  className={styles.submitButton}
                  onClick={(e) => handleClick(e)}
                >
                  {/* <FaRegSave style={{ marginRight: ".5rem", fontSize: "15px" }} /> */}
                  Go to the Platform
                </button>
              </div>
            )}
          </div>
          <div className={styles.steps}>
            <span
              className={`${styles.step} ${step === 0 ? styles.active : ""}`}
            >
              1
            </span>
            <span
              className={`${styles.step} ${step === 1 ? styles.active : ""}`}
            >
              2
            </span>
            <span
              className={`${styles.step} ${step === 2 ? styles.active : ""}`}
            >
              3
            </span>
            <span
              className={`${styles.step} ${step === 3 ? styles.active : ""}`}
            >
              4
            </span>
            <span
              className={`${styles.step} ${step === 4 ? styles.active : ""}`}
            >
              5
            </span>
            <span
              className={`${styles.step} ${step === 5 ? styles.active : ""}`}
            >
              6
            </span>
            <span
              className={`${styles.step} ${step === 6 ? styles.active : ""}`}
            >
              7
            </span>
          </div>
        </>
      )}
      <ExchangesModal show={showExchanges} setShow={setShowExchanges} />
      <IntegrationAlert
        show={showIntegrationAlert}
        setShow={setShowIntegrationAlert}
        goStep={setStep}
      />
      <Modal
        open={showSkipModal}
        closable={false}
        onCancel={undefined}
        footer={null}
        centered
        width={400}
        children={
          <div className={styles.skipModal}>
            <LazyLoadImage
              src={warn}
              alt="warn"
              effect="blur"
              width="100px"
              height="100px"
            />
            {/*<img src={warn} alt="note" />*/}
            <p className={styles.skipModalText}>
              The next steps are optional, and you can skip them.
            </p>
            <div className={styles.skipModalBtns}>
              <span onClick={() => setShowSkipModal(false)}>Continue</span>
              <span
                onClick={() => {
                  setShowSkipModal(false);
                  setStep(6);
                }}
              >
                Skip All
              </span>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default StartWizard;
