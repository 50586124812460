import React, { useEffect } from "react";

const TickerWidget = ({ symbolsData = [] }) => {
  useEffect(() => {
    const tickerScript = document.createElement("script");
    tickerScript.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
    tickerScript.async = true;
    const symbols = symbolsData
      .filter((symbol) => symbol !== "STETH")
      .map((symbol) => {
        let exchange = "BINANCE";
        if (symbol === "STETH") exchange = "COINBASE";

        return {
          proName: `${exchange}:${symbol}USDT`,
          title: `${symbol}/USDT`,
        };
      });
    // const proName = `BINANCE:${symbol}USDT`;
    // const title = `${symbol}/USDT`;
    tickerScript.innerHTML = JSON.stringify({
      symbols,
      colorTheme: "light",
      isTransparent: false,
      displayMode: "regular",
      locale: "en",
    });
    document.getElementById("ticker_widget").appendChild(tickerScript);

    // Cleanup script element when component unmounts or symbol changes
    return () => {
      document.getElementById("ticker_widget").innerHTML = "";
    };
  }, [symbolsData]);

  return (
    <div
      className="tradingview-widget-container"
      style={{ position: "relative", zIndex: 1 }}
    >
      <div id="ticker_widget"></div>
    </div>
  );
};

export default TickerWidget;
