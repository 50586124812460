import { Input } from "antd";
import React from "react";

const SixDigitInput = ({ value = "", onChange, onBlur, onFocus }) => {
  const handleInputChange = (e, index) => {
    const newValue = e.target.value;

    // Convert the current value to an array of characters
    const updatedValue = value.split("");

    // Update the character at the current index
    updatedValue[index] = newValue;

    // Convert the array back to a string and pass it to the onChange function
    onChange(updatedValue.join(""));

    // Move focus to the next input if a digit is entered
    if (newValue && index < 5) {
      document.getElementById(`digit-${index + 1}`).focus();
    }

    // Move focus to the previous input if the current input is cleared
    if (!newValue && index > 0) {
      document.getElementById(`digit-${index - 1}`).focus();
    }
  };

  const handleKeyDown = (e, index) => {
    // Handle backspace key press to focus the previous input
    if (e.key === "Backspace" && !value[index] && index > 0) {
      document.getElementById(`digit-${index - 1}`).focus();
      // Update the previous input value to empty (to handle deletion)
      const updatedValue = value.split("");
      updatedValue[index - 1] = "";
      onChange(updatedValue.join(""));
    }
  };

  const handlePaste = (e, startIndex) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").replace(/\D/g, ""); // Only keep numeric values
    const updatedValue = value.split("");

    // Handle the paste data from the startIndex onward
    let pasteIndex = 0;
    for (let i = 0; i < 6 && pasteIndex < pasteData.length; i++) {
      if (!updatedValue[i]) {
        // Only replace if the input is empty
        updatedValue[i] = pasteData[pasteIndex++];
      }
    }

    // Update the value with the pasted digits
    onChange(updatedValue.join(""));

    // Focus the last input affected by the paste or the last available input
    const lastFilledIndex = Math.min(startIndex + pasteData.length - 1, 5);
    document.getElementById(`digit-${lastFilledIndex}`).focus();
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        maxWidth: "400px",
        gap: "1rem",
        fontFamily: "Poppins-Regular",
      }}
    >
      {[...Array(6)].map((_, index) => (
        <Input
          key={index}
          id={`digit-${index}`}
          type="text"
          value={value[index] || ""}
          maxLength={1}
          onChange={(e) => handleInputChange(e, index)}
          onBlur={onBlur}
          onFocus={() => {
            if (onFocus) onFocus();
          }}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={(e) => handlePaste(e, index)} // Handle paste for each input
          style={{
            width: "2.5rem",
            textAlign: "center",
            padding: ".5rem",
          }}
        />
      ))}
    </div>
  );
};

export default SixDigitInput;
