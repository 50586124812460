import { BsCaretRightFill } from "react-icons/bs";
import React, { useState } from "react";
import styles from "../../styles/shared/service-activation/ServiceActivationPaymentMethods.module.css";
import { Radio } from "antd";
import { SiBitcoinsv } from "react-icons/si";
import { FaRegCreditCard } from "react-icons/fa";
import ActivateServiceByPayment from "./ActivateServiceByPayment";
import DisplayActivationWithPaymentMethodsResponse from "./DisplayActivationWithPaymentMethodsResponse";

function ServiceActivationPaymentMethods({ service, setStep }) {
  const [paymentMethod, setPaymentMethod] = useState("crypto");
  const [content, setContent] = useState("methods");

  const selectPaymentMethod = (e) => {
    setPaymentMethod(e.target.value);
  };

  return (
    <div>
      {content === "methods" && (
        <>
          <h4>
            <BsCaretRightFill />
            Just choose how you'd like to pay:
          </h4>
          <Radio.Group
            className={styles.methods}
            value={paymentMethod}
            onChange={selectPaymentMethod}
          >
            <Radio value={"crypto"} className={styles.method}>
              <SiBitcoinsv />
              Crypto
            </Radio>
            <Radio value={"card"} className={styles.method}>
              <FaRegCreditCard /> Credit Card
            </Radio>
          </Radio.Group>
        </>
      )}

      {content === "result" && (
        <DisplayActivationWithPaymentMethodsResponse
          method={paymentMethod}
          changeContent={setContent}
          setStep={setStep}
        />
      )}

      {content === "methods" && paymentMethod && (
        <>
          <ActivateServiceByPayment
            service={service}
            paymentType={paymentMethod}
            style={{ marginTop: "1rem" }}
            changeContent={setContent}
            setStep={setStep}
          />
        </>
      )}
    </div>
  );
}

export default ServiceActivationPaymentMethods;
