import styles from "../../../styles/Components/new-insight/create-strategy/CreateStrategyModal.module.css";
import React, { useEffect, useState } from "react";
import CreateStrategySidebar from "./CreateStrategySidebar";
import CreateStrategyMain from "./CreateStrategyMain";
import { useStrategy } from "./useCreateStrategyContext";
import CreateStrategyForm from "./CreateStrategyForm";
import SearchStrategy from "./SearchStrategy";

function CreateStrategy({ action, data }) {
  const [step, setStep] = useState(1);
  const {
    state: { selectedFeatures, strategyName },
    setDefaultValues,
  } = useStrategy();

  useEffect(() => {
    if (selectedFeatures.length === 0 && !strategyName && action === "edit") {
      setDefaultValues(data);
    }
  }, [action, data, selectedFeatures.length, setDefaultValues, strategyName]);

  return (
    <div className={styles.container}>
      {step === 2 ? (
        <CreateStrategyForm data={data} goToPrevStep={() => setStep(1)} />
      ) : (
        <>
          <SearchStrategy />
          <div className={styles.body}>
            <CreateStrategySidebar />
            <CreateStrategyMain setStep={setStep} />
          </div>
        </>
      )}
    </div>
  );
}

export default CreateStrategy;
