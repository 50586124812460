import styles from "../../../styles/Components/new-insight/chart-section/SignalsSummary.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import SignalSourceBox from "./SignalSourceBox";
import { Tooltip } from "antd";
import { MdMoreHoriz, MdOutlineInfo } from "react-icons/md";
import { signalStrengthConverter } from "../../trading-assistant/SignalStrengthUi";
import useServiceStatus from "../../../hooks/useServiceStatus";
import {
  setBasicTradeInfo,
  setMode,
  setStep,
} from "../../../redux/trade/actions";
import React, { useState } from "react";
import TradeModal from "../../../shared/trade-modal/TradeModal";
import ServiceActivationModal from "../../../shared/service-activation/ServiceActivationModal";
import HideContentIcon from "../../mini-app/shared/HideContentIcon";

const strengthColors = {
  1: "#A9A9A9",
  2: "#4682B4",
  3: "#228B22",
  4: "#8B0000",
  5: "#00008B",
};

const SourceBox = ({ name, data }) => {
  const {
    newInsightState: {
      coefficients: { stop_loss: stopLossCFC, take_profit: takeProfitCFC },
      insightMarketType: market,
    },
  } = useSelector((state) => state);
  const [featureName, featureDesc] = name.split("-");
  const [searchParams] = useSearchParams();
  const [showTradeModal, setShowTradeModal] = useState(false);
  const [showServiceActivationModal, setShowServiceActivationModal] =
    useState(false);

  const dispatch = useDispatch();
  const { isActive } = useServiceStatus("insight");
  const symbol = searchParams.get("symbol") || "BTC";

  const {
    suggestion,
    "signal strength": strength,
    stop_loss_percent: stopLoss,
    take_profit_percent: takeProfit,
    signal_date: date,
    other_info: { category, subcategory },
    suggested_amount_percentage: amountPercent,
  } = data;

  const showActivationModal = () => setShowServiceActivationModal(true);
  const tradeHandler = () => {
    if (!isActive) return showActivationModal();

    dispatch(
      setBasicTradeInfo({
        market,
        asset: symbol,
        tradeType: suggestion,
        stopLossPercent: stopLoss * stopLossCFC,
        takeProfitPercent: takeProfit * takeProfitCFC,
        amountPercent: amountPercent * 100,
      }),
    );
    dispatch(setStep("1-d"));
    dispatch(setMode("ai-suggestion"));
    setShowTradeModal(true);
  };

  return (
    <>
      <li className={styles.detailsContainer}>
        <span className={styles.name}>
          {featureDesc && (
            <Tooltip
              title={featureDesc}
              className={styles.tooltip}
              color="geekblue"
            >
              <MdOutlineInfo size={15} />
            </Tooltip>
          )}
          {featureName}
        </span>
        <span
          className={`${styles.btn} ${
            !isActive
              ? styles.hideContent
              : suggestion === "Buy"
                ? styles.buy
                : suggestion === "Sell"
                  ? styles.sell
                  : suggestion === "Hold"
                    ? styles.hold
                    : ""
          }`}
          onClick={tradeHandler}
        >
          {isActive ? suggestion : <HideContentIcon />}
        </span>
        <span
          style={
            isActive ? { color: `${stopLoss > 0 ? "#15B392" : "#F24C3D"}` } : {}
          }
        >
          {isActive ? (
            stopLoss.toFixed(2) + "%"
          ) : (
            <HideContentIcon
              style={{ display: "inline-block" }}
              clickHandler={showActivationModal}
            />
          )}
        </span>
        <span
          style={
            isActive
              ? { color: `${takeProfit > 0 ? "#15B392" : "#F24C3D"}` }
              : {}
          }
        >
          {isActive ? (
            takeProfit.toFixed(2) + "%"
          ) : (
            <HideContentIcon
              style={{ display: "inline-block" }}
              clickHandler={showActivationModal}
            />
          )}
        </span>
        <span style={{ color: `${strengthColors[strength]}`, fontWeight: 600 }}>
          {signalStrengthConverter[strength]}
        </span>
        {/*<span>*/}
        {/*  <MdMoreHoriz />*/}
        {/*</span>*/}
      </li>
      {showTradeModal && (
        <TradeModal setShow={setShowTradeModal} show={showTradeModal} />
      )}
      {showServiceActivationModal && (
        <ServiceActivationModal
          service={"insai"}
          show={showServiceActivationModal}
          setShow={setShowServiceActivationModal}
        />
      )}
    </>
  );
};

function SignalsSummary() {
  const {
    newInsightState: { signalData },
  } = useSelector((state) => state);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const symbol = searchParams.get("symbol") || "BTC";
  const signalInfo = signalData.find((s) => s?.coin === symbol);

  if (!signalInfo || signalData.length === 0)
    return (
      <div className={styles.messageBox}>
        <h4>Discover more valuable insights with our signals!</h4>
        <span
          className={styles.navigator}
          onClick={() => navigate("/insight/signal")}
        >
          See signals
        </span>
      </div>
    );

  const features = Object.keys(signalInfo).filter((k) => k !== "coin");

  return (
    <ul className={styles.container}>
      <li className={styles.coinBox}>
        <div className={styles.images}>
          <img
            className={styles.coin}
            src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${symbol}.png`}
            alt={symbol}
          />
          <img
            className={styles.tether}
            src="https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/USDT.png"
            alt="tether"
          />
        </div>
        <div className={styles.coinName}>
          <span>{symbol}</span>
          <span className={styles.usdt}> / USDT</span>
        </div>
      </li>

      <li className={`${styles.detailsContainer} ${styles.labels}`}>
        <span>Source</span>
        <span>Suggestion</span>
        <span>SL</span>
        <span>TP</span>
        <span>Strength</span>
        {/*<span>More</span>*/}
      </li>

      {features.map((f) => (
        <SourceBox data={signalInfo[f]} name={f} />
      ))}

      {/*{features.map((f) => (*/}
      {/*  <SignalSourceBox feature={f} signal={signalInfo[f]} />*/}
      {/*  // <li key={f}>*/}
      {/*  //   {console.log(signalInfo[f])}*/}
      {/*  //   <div className={styles.featureName}>{f}</div>*/}
      {/*  //   <div className={styles.featureInfo}>*/}
      {/*  //     <span*/}
      {/*  //       className={`${styles.btn} ${*/}
      {/*  //         signalInfo[f]?.suggestion === "Buy"*/}
      {/*  //           ? styles.buy*/}
      {/*  //           : signalInfo[f]?.suggestion === "Sell"*/}
      {/*  //           ? styles.sell*/}
      {/*  //           : signalInfo[f]?.suggestion === "HoldBuy"*/}
      {/*  //           ? styles.hold*/}
      {/*  //           : ""*/}
      {/*  //       } `}*/}
      {/*  //     >*/}
      {/*  //       {signalInfo[f]?.suggestion}*/}
      {/*  //     </span>*/}
      {/*  //   </div>*/}
      {/*  // </li>*/}
      {/*))}*/}
    </ul>
  );
}

export default SignalsSummary;
