import { useEffect } from "react";

function UsePageTitle(page) {
  useEffect(() => {
    const defaultTitle = "CryptoCrispy App"; // Save the previous title
    document.title = `${page} - ${defaultTitle}`; // Set the new title

    return () => {
      document.title = defaultTitle; // Restore the previous title on unmount
    };
  }, [page]);
}

export default UsePageTitle;
