import styles from "../styles/ui/ErrorComponent.module.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import errorImage from "../asset/Images/error (2).svg";
import React, { useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { logErrorToTelegram } from "../utility/action-helper";

function ErrorComponent({ error, resetErrorBoundary }) {
  const dispatch = useDispatch();
  const handleReport = () => {
    console.log("error");
    resetErrorBoundary();
  };

  useEffect(() => {
    let localToken = JSON.parse(
      localStorage.getItem("aws-amplify-federatedInfo"),
    )?.token;
    if (localToken) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${localToken}`;
      let userData = JSON.parse(localStorage.getItem("aws-amplify-USERDATA"));
      const email = userData?.["cognito:username"] || "email";
      const user = userData?.["custom:custom_username"] || "username";

      dispatch(
        logErrorToTelegram(
          user,
          email,
          "frontEnd_error",
          "@hadis_mtb",
          error.message,
          window?.location?.href,
          false,
        ),
      );
    }
  }, []);

  return (
    <div className={styles.container}>
      <LazyLoadImage
        src={errorImage}
        alt="Error..."
        height="300px"
        width="100%"
        effect="blur"
        wrapperClassName={styles.preview}
      />
      <h3>Something went wrong!</h3>
      <p>Hang tight, we'll fix it shortly!</p>
      <div className={styles.btn} onClick={handleReport}>
        Back to Home
      </div>
    </div>
  );
}

export default ErrorComponent;
