import React, { useState } from "react";
import IntegratedExchangeList from "./portfolio-section/IntegratedExchangeList";
import styles from "../../styles/Components/integration/TradeHistory.module.css";
import { Button } from "antd";
import { FiRefreshCcw } from "react-icons/fi";
import {
  getExchangeBalanceChart,
  getExchangeComprehensiveTradeHistory,
  getExchangeTradeHistory,
} from "../../redux/exchange/actions";
import { useDispatch, useSelector } from "react-redux";
import ExchangesAndPaperTradingTab from "../../shared/exchanges-papertrading-tabs/ExchangesAndPaperTradingTab";
import ComprehensiveHistoryTable from "./history-section/ComprehensiveHistoryTable";
import ExchangeHistoryTable from "./history-section/ExchangeHistoryTable";
import useTrackSpendTime from "../../hooks/useTrackSpendTime";

function TradeHistory() {
  const {
    main: { userData },
    exchangeState: {
      exchangeHistoryLoading,
      selectedPortfolioExchange,
      exchangeComprehensiveHistoryLoading,
      exchangeHistory,
    },
  } = useSelector((state) => state);

  const [tableMode, setTableMode] = useState("positions");

  useTrackSpendTime("exchange-history", exchangeHistory?.length > 0);

  const dispatch = useDispatch();
  const handleRefresh = () => {
    if (tableMode === "orders") {
      if (exchangeHistoryLoading) return;
      dispatch(
        getExchangeTradeHistory(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          selectedPortfolioExchange,
        ),
      );
    }
    if (tableMode === "positions") {
      if (exchangeComprehensiveHistoryLoading) return;
      dispatch(
        getExchangeComprehensiveTradeHistory(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          selectedPortfolioExchange,
        ),
      );
    }
  };

  return (
    <div>
      <h3 className={styles.title}>
        {console.log("run history")}
        <strong>Trade History</strong>
      </h3>
      <ExchangesAndPaperTradingTab
        activeTab={"exchange"}
        pageType={"history"}
      />

      <IntegratedExchangeList />

      <div className={styles.btns}>
        {/*<Button className={styles.paperTradingBtn} onClick={goToPaperTrading}>*/}
        {/*  Paper Trading*/}
        {/*</Button>*/}
        <ul className={styles.options}>
          <li className={styles.tableMode}>
            <span
              className={`${styles.tableModeBtn} ${styles.positionBtn} ${tableMode === "positions" ? styles.selectedMode : ""}`}
              onClick={() => setTableMode("positions")}
            >
              Positions
            </span>
            <span
              className={`${styles.tableModeBtn} ${styles.orderBtn} ${tableMode === "orders" ? styles.selectedMode : ""}`}
              onClick={() => setTableMode("orders")}
            >
              Orders
            </span>
          </li>
          <li>
            <Button
              onClick={handleRefresh}
              loading={exchangeHistoryLoading}
              type={"primary"}
              className={styles.refreshBtn}
            >
              {!exchangeHistoryLoading && <FiRefreshCcw />} Refresh
            </Button>
          </li>
        </ul>
      </div>

      {tableMode === "orders" && <ExchangeHistoryTable />}
      {tableMode === "positions" && (
        <ComprehensiveHistoryTable type="exchange" />
      )}
    </div>
  );
}

export default TradeHistory;
