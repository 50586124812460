import styles from "../../styles/shared/service-activation/ActivateServiceByLowCredit.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Button } from "antd";
import { FaCircleInfo } from "react-icons/fa6";
import { activateServiceWithCredit } from "../../redux/pricing-plan/action";
import DisplayActivationWithCreditResponse from "./DisplayActivationWithCreditResponse";
import {
  renderServiceActivationMode,
  servicesCost,
} from "../../utility/service-activation";

function ActivateServiceByLowCredit({ service, setStep }) {
  const {
    profile: { profileData },
    pricingPlan: { activateServiceWithCreditStatus, pricingPlan },
    main: { userData },
  } = useSelector((state) => state);

  const [content, setContent] = useState("selectDuration");

  const dispatch = useDispatch();

  const cost = pricingPlan[service] || servicesCost[service];
  const credit = profileData?.credit / 100;
  const maximumDuration = Math.trunc((30 * credit) / cost);

  const suggestedDuration = Math.min(maximumDuration, 30);

  const activateServiceByCredit = () => {
    if (activateServiceWithCreditStatus === "loading") return;

    const amount = (cost * suggestedDuration) / 30;

    const redirectUrl = window.location.href;

    dispatch(
      activateServiceWithCredit(
        userData?.["custom:custom_username"],
        userData?.email,
        renderServiceActivationMode(service),
        `${amount}`,
        redirectUrl,
        redirectUrl,
      ),
    );
    setContent("result");
    setStep(3);
  };

  return (
    <div>
      {content === "selectDuration" && (
        <>
          <div className={styles.text}>
            <FaCircleInfo size={25} color={"#0C4CFC"} />
            <h3>
              Activate this service for {suggestedDuration} day
              {suggestedDuration > 1 ? "s" : ""} using your available account
              credit.
            </h3>
          </div>
          <div className={styles.btn}>
            <Button
              className={styles.submitBtn}
              loading={activateServiceWithCreditStatus === "loading"}
              onClick={activateServiceByCredit}
            >
              Submit
            </Button>
          </div>
        </>
      )}

      {content === "result" && (
        <DisplayActivationWithCreditResponse
          lowCredit={false}
          changeContent={setContent}
          setStep={setStep}
        />
      )}
    </div>
  );
}

export default ActivateServiceByLowCredit;
