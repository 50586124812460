import Trends from "./watchlist/Trends";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getWatchLists } from "../../redux/new-insight/actions";
import { FaArrowDownLong, FaArrowUpLong } from "react-icons/fa6";
import { FaFire } from "react-icons/fa";
import SortBy from "../../ui/SortBy";
import TrendsTable from "./watchlist/TrendsTable";
import DisplayModeSwitcher from "./watchlist/DisplayModeSwitcher";

function InsightWatchlist() {
  const {
    main: { userData },
    newInsightState: { watchlists, watchlistStatus, watchlistsFetched },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const sortOptions = [
    {
      value: "tops",
      label: "Top coins",
      icon: <FaFire />,
    },
    {
      value: "losers",
      label: "Losers",
      icon: <FaArrowUpLong />,
    },
    {
      value: "gainers",
      label: "Gainers",
      icon: <FaArrowDownLong />,
    },
  ];

  useEffect(() => {
    if (
      userData?.email &&
      !watchlistsFetched &&
      watchlistStatus !== "loading" &&
      watchlistStatus !== "error"
    ) {
      dispatch(
        getWatchLists(userData["custom:custom_username"], userData?.email),
      );
    }
  }, [userData, watchlists, watchlistStatus, watchlistsFetched, dispatch]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginLeft: "auto",
          marginBottom: "1rem",
          gap: ".5rem",
        }}
      >
        <DisplayModeSwitcher />
        <SortBy options={sortOptions} />
      </div>
      <TrendsTable />
      {/*<Trends />*/}
    </>
  );
}

export default InsightWatchlist;
