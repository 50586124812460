import { useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  message,
  Modal,
  Radio,
  Slider,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { MdOutlineInfo } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import useServiceStatus from "../../hooks/useServiceStatus";
import { getCustomRemainingTime } from "../../utility/formatter-helper";
import useUserTier from "../../hooks/useUserTier";

import styles from "../../styles/Components/pricing-plan/ActivateServices.module.css";
import { calculateOffPrice } from "../../utility/helper";
import { servicesCost } from "../../utility/service-activation";
import { activateServiceWithCredit } from "../../redux/pricing-plan/action";
import { BsPatchQuestionFill } from "react-icons/bs";
import PaymentOptionsModal from "./activate-services/PaymentOptionsModal";

const { Text } = Typography;

function ActivateServices() {
  const {
    main: { userData },
    profile: { profileData, loading },
    pricingPlan: { pricingPlan, activateServiceWithCreditStatus },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [selectedServices, setSelectedServices] = useState([]);

  const [selectedDuration, setSelectedDuration] = useState("1");
  const [showSlider, setShowSlider] = useState(false);
  const [showPaymentsModal, setShowPaymentsModal] = useState(false);

  const offPercentage =
    pricingPlan["yearly_discount"] || servicesCost["yearly_discount"];

  const { isActive: aiAssistantStatus, expirationDate: aiAssistantExDate } =
    useServiceStatus("aiAssistant");
  const { isActive: alertStatus, expirationDate: alertExDate } =
    useServiceStatus("alert");
  const { isActive: botStatus, expirationDate: botExDate } =
    useServiceStatus("bot");
  const { isActive: insightStatus, expirationDate: insightExDate } =
    useServiceStatus("insight");
  const {
    isActive: tradeSimulatorStatus,
    expirationDate: tradeSimulatorExDate,
  } = useServiceStatus("tradeSimulator");

  const { serviceType: aiKey } = useUserTier("myais");
  const { serviceType: alertKey } = useUserTier("alert");
  const { serviceType: botKey } = useUserTier("mybot");
  const { serviceType: insightKey } = useUserTier("insai");
  const { serviceType: tradeSimulatorKey } = useUserTier("trsim");

  const servicesCost = useMemo(() => {
    return {
      myais: pricingPlan[aiKey],
      alert: pricingPlan[alertKey],
      mybot: pricingPlan[botKey],
      insai: pricingPlan[insightKey],
      trsim: pricingPlan[tradeSimulatorKey],
    };
  }, [aiKey, alertKey, botKey, insightKey, pricingPlan, tradeSimulatorKey]);

  const totalPrice = selectedServices.reduce(
    (total, service) => total + servicesCost[service],
    0,
  );
  // total price after discount
  const finalPrice =
    selectedDuration === "1"
      ? totalPrice
      : calculateOffPrice(offPercentage, totalPrice, selectedDuration, "price");
  const servicesString = selectedServices.join("_");
  const activationMode = `activate_${servicesString}`;

  // Services data, including active status and expiration date
  const services = useMemo(() => {
    return [
      {
        label: "AI Assistant",
        key: "myais",
        active: aiAssistantStatus,
        exDate: aiAssistantExDate,
      },
      {
        label: "Alert System",
        key: "alert",
        active: alertStatus,
        exDate: alertExDate,
      },
      { label: "Bot", key: "mybot", active: botStatus, exDate: botExDate },
      {
        label: "Insights",
        key: "insai",
        active: insightStatus,
        exDate: insightExDate,
      },
      {
        label: "Trade Simulator",
        key: "trsim",
        active: tradeSimulatorStatus,
        exDate: tradeSimulatorExDate,
      },
    ];
  }, [
    aiAssistantExDate,
    aiAssistantStatus,
    alertExDate,
    alertStatus,
    botExDate,
    botStatus,
    insightExDate,
    insightStatus,
    tradeSimulatorExDate,
    tradeSimulatorStatus,
  ]);

  const handleChange = (checkedValues) => {
    if (loading) return;
    if (checkedValues.length === 0) {
      setSelectedDuration("1");
      setShowSlider(false);
    }
    setSelectedServices(checkedValues);
  };

  const selectDurationHandler = (e) => {
    if (selectedServices.length === 0) return;
    if (e.target.value === "custom") {
      setShowSlider(true);
      setSelectedDuration("1");
      return;
    } else {
      setShowSlider(false);
    }

    setSelectedDuration(e.target.value);
  };

  const activateService = () => {
    if (activateServiceWithCreditStatus === "loading") return;
    const url = window.location.href;

    if (finalPrice <= profileData?.credit) {
      return Modal.confirm({
        title: "Note",
        content: "Are you sure you want to activate this service(s)?",
        centered: true,
        okText: "Yes",
        icon: (
          <BsPatchQuestionFill
            size={18}
            color="#1677ff"
            style={{ marginRight: ".2rem", marginTop: ".2rem" }}
          />
        ),
        okButtonProps: {
          style: {
            backgroundColor: "#0c3fce",
            borderColor: "#0c3fce",
          },
        },
        onOk: () => {
          dispatch(
            activateServiceWithCredit(
              userData?.["custom:custom_username"],
              userData?.email,
              activationMode,
              `${finalPrice}`,
              url,
              url,
              true,
            ),
          );
          setSelectedServices([]);
        },
        onCancel: () => {
          Modal.destroyAll();
        },
      });
    } else {
      setShowPaymentsModal(true);
    }
  };

  const closePaymentModal = () => {
    setSelectedServices([]);
    setShowPaymentsModal(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div>
          <Text strong>1.Select the services you want to activate:</Text>
          <Checkbox.Group
            className={styles.options}
            value={selectedServices}
            onChange={handleChange}
          >
            {services.map((service) => (
              <div key={service.label} className={styles.option}>
                <Checkbox
                  value={service.key}
                  disabled={service.active} // Disable if service is active
                >
                  {service.label}
                </Checkbox>
                {service.active && (
                  <Tooltip
                    title={`Active until: ${getCustomRemainingTime(service.exDate)}`}
                    color="green"
                  >
                    <MdOutlineInfo className={styles.infoIcon} />
                  </Tooltip>
                )}
              </div>
            ))}
          </Checkbox.Group>
        </div>

        <div
          className={`${styles.durationBox} ${selectedServices.length === 0 ? styles.disabled : ""}`}
        >
          <Text strong>
            2.How many months would you like to activate this service for?
          </Text>

          <Radio.Group
            className={styles.radioGroup}
            value={showSlider ? "custom" : selectedDuration}
            onChange={selectDurationHandler}
          >
            <Radio className={styles.radio} value="1">
              1 Month
            </Radio>
            <Radio className={styles.radio} value="3">
              3 Months
              <span className={styles.discount}>
                {calculateOffPrice(offPercentage, 0, 3, "off")}%
              </span>
            </Radio>
            <Radio className={styles.radio} value="6">
              6 Months
              <span className={styles.discount}>
                {calculateOffPrice(offPercentage, 0, 6, "off")}%
              </span>
            </Radio>
            <Radio className={styles.radio} value="12">
              1 Year
              <span className={styles.discount}>
                {calculateOffPrice(offPercentage, 0, 12, "off")}%
              </span>
            </Radio>
            <Radio className={styles.radio} value="custom">
              Custom
            </Radio>
          </Radio.Group>
          {showSlider && (
            <Slider
              min={1}
              max={12}
              value={+selectedDuration || 1}
              onChange={(e) => setSelectedDuration(`${e}`)}
              tooltip={{
                formatter: (value) => `${value} Month(s)`,
                open: true,
                color: "geekblue",
                placement: "bottom",
              }}
            />
          )}
        </div>
      </div>

      <div className={styles.totalPrice}>
        <div>
          <Text strong>Discount:</Text>
          {"  "}
          {selectedDuration === "1" ? (
            <Text strong type="secondary">
              0
            </Text>
          ) : (
            <Text type="secondary" strong>
              $
              {calculateOffPrice(
                offPercentage,
                totalPrice,
                +selectedDuration,
                "offeredPrice",
              ).toFixed(2)}{" "}
              (
              {calculateOffPrice(
                offPercentage,
                totalPrice,
                +selectedDuration,
                "off",
              )}
              %)
            </Text>
          )}
        </div>

        <div>
          <Text strong>Price Before Discount:</Text>
          <Text type="secondary" strong>
            {" "}
            ${totalPrice * (selectedDuration || 1)}
          </Text>
        </div>

        <div>
          <Text strong>Price After Discount:</Text>{" "}
          <Text
            strong
            type={selectedDuration === "1" ? "secondary" : "success"}
          >
            ${finalPrice}
          </Text>
        </div>
      </div>

      <div className={styles.submitBtn}>
        <Button
          disabled={!selectedServices.length}
          loading={activateServiceWithCreditStatus === "loading"}
          onClick={activateService}
        >
          Activate
        </Button>
      </div>

      {loading && (
        <div
          className={styles.loader}
          onClick={() => message.loading("Please wait...")}
        >
          <Spin />
          <span className={styles.loaderText}>
            Please wait! We are checking your services status...
          </span>
        </div>
      )}

      {showPaymentsModal && (
        <PaymentOptionsModal
          show={showPaymentsModal}
          setShow={closePaymentModal}
          activationMode={activationMode}
          amount={finalPrice}
        />
      )}
    </div>
  );
}

export default ActivateServices;
