import React, { useEffect, useState } from "react";
import DashboardLayout from "../../components/layout/DashboardLayout";
import styles from "../../styles/pages/integration/Integration.module.css";
import { useParams } from "react-router";
import TopExchanges from "../../shared/start-wizard/TopExchanges";
import { useNavigate } from "react-router-dom";
import Portfolio from "../../components/Integration/Portfolio";
import TradeHistory from "../../components/Integration/TradeHistory";
import { useSelector } from "react-redux";
import ErrorComponent from "../../components/errors/ErrorComponent";
import DisplayRatingModal from "../../shared/DisplayRatingModal";
import usePageTitle from "../../hooks/usePageTitle";

function Integration({ showRatingModal, setVisitedPage }) {
  const {
    exchangeState: { exchangesError, exchangesInfo, integratedData },
  } = useSelector((state) => state);
  const params = useParams();

  const [canShowRatingModal, setCanShowRatingModal] = useState(false);

  const [section, setSection] = useState(params.section || "default");
  const navigate = useNavigate();
  usePageTitle("Integration");

  useEffect(() => {
    // Dynamically update `canShowRatingModal` whenever dependencies change
    const hasPortfolioVisit = localStorage.getItem("visit-exchange-portfolio");
    const hasHistoryVisit = localStorage.getItem("visit-exchange-history");
    const hasExchangesInfo = Object.keys(exchangesInfo).length > 0;
    const hasIntegratedData = integratedData?.length > 0;

    setCanShowRatingModal(
      Boolean(
        hasPortfolioVisit &&
          hasHistoryVisit &&
          hasExchangesInfo &&
          hasIntegratedData,
      ),
    );
  }, [exchangesInfo, integratedData]);

  useEffect(() => {
    if (section === "default") {
      navigate("/integration");
    } else {
      navigate(`/integration/${section}`);
    }
  }, [section]);

  useEffect(() => {
    setSection(params.section || "default");
  }, [params]);

  return (
    <DashboardLayout hasDemo>
      <div className={styles.wrapper}>
        <div className={styles.main}>
          {!params.section && (
            <div className={styles.topExchanges}>
              {exchangesError ? (
                <ErrorComponent />
              ) : (
                <>
                  <div className={styles.topExchangeTitle}>
                    <h3>
                      <strong>Connect to your Exchange</strong>
                    </h3>
                    <p>
                      Explore the top exchanges below and integrate your
                      accounts effortlessly. For the complete list of 100
                      exchanges, click for more.
                    </p>
                  </div>
                  <TopExchanges />
                </>
              )}
            </div>
          )}
          {params.section === "portfolio" && <Portfolio />}
          {params.section === "trade-history" && <TradeHistory />}
        </div>

        <DisplayRatingModal
          page="integration"
          showRatingModal={showRatingModal}
          setVisitedPage={setVisitedPage}
          condition={canShowRatingModal}
        />
        {/*<div className={styles.sidebar}>*/}
        {/*  <MarketMenu />*/}
        {/*  <ContentMenu changeSection={setSection} section={section} />*/}
        {/*</div>*/}
      </div>
    </DashboardLayout>
  );
}

export default Integration;
