import DashboardStatusBox from "./DashboardStatusBox";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { useSelector } from "react-redux";
import styles from "../../../styles/Components/dashboard/statusbar/ServicesInfo.module.css";
import { TbAlertTriangle } from "react-icons/tb";
import React from "react";
import { FaRobot, FaSignal } from "react-icons/fa";
import { MdTrendingUp } from "react-icons/md";
import { AiOutlineBarChart } from "react-icons/ai";
import { Tooltip } from "antd";

const servicesData = {
  ai: {
    name: "AI Signal",
    icon: <FaSignal />,
  },
  bot: {
    name: "Bot",
    icon: <FaRobot />,
  },
  simulator: {
    name: "Trade Simulator",
    icon: <MdTrendingUp />,
  },
  insight: {
    name: "Insights",
    icon: <AiOutlineBarChart />,
  },
};

const Info = ({ title, status, type, alertMsg }) => {
  const { icon, name } = servicesData[type];
  return (
    <div className={styles.serviceInfo}>
      <span className={styles.serviceTitle}>
        {icon}
        {/*<img src={icon} alt={name} className={styles.serviceIcon} />*/}
        {name}
      </span>
      {status && (
        <Tooltip title={alertMsg} color={"red"}>
          <TbAlertTriangle size={15} className={styles.warnIcon} />
        </Tooltip>
      )}
    </div>
  );
};

function ServicesInfo() {
  const {
    profile: { loading, profileData },
  } = useSelector((state) => state);

  const isInsightActive = profileData?.insai_active === "True";
  const isAiSignalActive = profileData?.myais_active === "True";
  const isBotActive = profileData?.mybot_active === "True";
  const isSimulatorActive = profileData?.trsim_active === "True";

  // basic_plan_service_alert
  // pro_plan_service_alert
  const showAlertForBasic = profileData?.basic_plan_service_alert !== "None";
  const showAlertForPro = profileData?.pro_plan_service_alert !== "None";
  const basicAlert = showAlertForBasic
    ? profileData?.basic_plan_service_alert
    : "";
  const proAlert = showAlertForPro ? profileData?.pro_plan_service_alert : "";

  return (
    <DashboardStatusBox
      title={"Active Services"}
      icon={<MdOutlineMiscellaneousServices />}
      loading={!profileData?.basic_plan_service_alert}
      path={"/pricing-billing/subscription"}
    >
      {!isAiSignalActive &&
      !isInsightActive &&
      !isBotActive &&
      !isSimulatorActive ? (
        "-"
      ) : (
        <div>
          {isAiSignalActive && (
            <Info
              title={"AI Signal"}
              status={showAlertForPro}
              alertMsg={proAlert}
              type="ai"
            />
          )}
          {isBotActive && (
            <Info
              title={"Bot"}
              status={showAlertForBasic}
              alertMsg={basicAlert}
              type="bot"
            />
          )}
          {isSimulatorActive && (
            <Info title={"Trade Simulator"} type="simulator" />
          )}
          {isInsightActive && <Info title={"Insights"} type="insight" />}
        </div>
      )}
    </DashboardStatusBox>
  );
}

export default ServicesInfo;
