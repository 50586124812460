import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import { Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { handleBlur, handleShowPass } from "../../utility/action";
import { SignUpValidation } from "../../utility/Validation/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { notify } from "../../utility/Toastify/toastify";
import poolData from "./AWSConfig/PoolData";
import { useNavigate } from "react-router-dom";
import "../../styles/pages/Auth/auth.css";
import Logo from "../../shared/Logo";
import Text from "../../components/auth/Text";
import ReferralModal from "../../components/auth/ReferralModal";
import GoogleSignUpButton from "./GoogleSignUpButton";
import { Modal } from "antd";
import { useDispatch } from "react-redux";
import { saveUserPass } from "../../redux/main/action/main";

function SignUpPage({ emailHandler }) {
  //btn loading
  const [loading, setLoading] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  let navigate = useNavigate();
  //validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(SignUpValidation),
  });

  const params = useParams();
  const dispatch = useDispatch();

  const [referralValue, setReferralValue] = useState("");
  const [showReferralModal, setShowReferralModal] = useState(false);
  const [submitData, setSubmitData] = useState({});
  const [checkCount, setCheckCount] = useState(0);
  const [googleLoading, setGoogleLoading] = useState(false);

  useEffect(() => {
    if (params.code) {
      setValue("referralValue", params["code"].split("=")[1]);
      setReferralValue(params["code"].split("=")[1]);
    }
  }, []);

  //form submit function
  const onsubmit = (data) => {
    if (referralValue.length === 0 && checkCount === 0) {
      setSubmitData(data);
      setShowReferralModal(true);
      setCheckCount(1);
      return;
    }
    setLoading(true);

    poolData.signUp(
      data?.email.toLowerCase(),
      data?.pass,
      [
        { Name: "email", Value: data?.email.toLowerCase() },
        {
          Name: "custom:custom_username",
          Value: data?.username,
        },
        { Name: "custom:about_us", Value: "" },
        {
          Name: "custom:referal_code",
          Value: referralValue.length < 10 ? "          " : referralValue,
        },
      ],
      [],
      (err, res) => {
        if (res) {
          emailHandler(res.user.username.toLowerCase());
          dispatch(saveUserPass([data?.email.toLowerCase(), data?.pass]));
          localStorage.setItem("userPass", [
            data?.email.toLowerCase(),
            data?.pass,
          ]);
          navigate("/auth/confirm");
          setLoading(false);
        }
        if (err) {
          notify(err?.message, "error");
          setLoading(false);
        }
      },
      null,
    );
    setCheckCount(0);
  };

  return (
    <>
      <div className="limiter">
        <div className="container-login100">
          {/*<Logo />*/}
          <div className="content">
            <Text />
            <div className="wrap-login100">
              <form
                className="login100-form validate-form"
                onSubmit={handleSubmit(onsubmit)}
              >
                <h4 className="form-title">
                  Simplify Your Crypto Trading Experience - Sign Up Now
                </h4>
                <div
                  className={`wrap-input100 validate-input ${
                    errors.username && "border-error"
                  }`}
                >
                  <input
                    className="input100  has-val"
                    type={"text"}
                    defaultValue={""}
                    {...register("username")}
                    onBlur={(e) => handleBlur(e)}
                  />
                  <span
                    className="focus-input100"
                    data-placeholder={"Username"}
                  ></span>
                </div>
                {errors.username && (
                  <span className="error-text">{errors.username.message}</span>
                )}

                <div
                  className={` wrap-input100 validate-input ${
                    errors.email && "border-error"
                  }`}
                >
                  <input
                    className="input100 has-val"
                    type={"text"}
                    {...register("email")}
                    onBlur={(e) => handleBlur(e)}
                  />
                  <span
                    className="focus-input100"
                    data-placeholder={"Email"}
                  ></span>
                </div>
                {errors.email && (
                  <span className="error-text">{errors.email.message}</span>
                )}

                <div
                  className={`wrap-input100 validate-input ${
                    errors.pass && "border-error"
                  }`}
                >
                  <span className="btn-show-pass">
                    <i
                      className="far fa-eye"
                      onClick={(e) => handleShowPass(e)}
                    ></i>
                  </span>
                  <input
                    className="input100 has-val"
                    type={"password"}
                    {...register("pass")}
                    onBlur={(e) => handleBlur(e)}
                  />
                  <span
                    className="focus-input100"
                    data-placeholder={"Password"}
                  ></span>
                </div>
                {errors.pass && (
                  <span className="error-text">{errors.pass.message}</span>
                )}

                <div
                  className={` wrap-input100 validate-input ${
                    errors.confirmPass && "border-error"
                  }`}
                >
                  <span className="btn-show-pass">
                    <i
                      className="far fa-eye"
                      onClick={(e) => handleShowPass(e)}
                    ></i>
                  </span>
                  <input
                    className="input100 has-val"
                    type={"password"}
                    {...register("confirmPass")}
                    onBlur={(e) => handleBlur(e)}
                  />
                  <span
                    className="focus-input100"
                    data-placeholder={"Confirm password"}
                  ></span>
                </div>
                {errors.confirmPass && (
                  <span className="error-text">
                    {errors.confirmPass.message}
                  </span>
                )}
                {/* <div className={"signUpSelectBoxContainer"}>
                  <span className={"signUpSelectBoxText"}>
                    How did you hear about us? (optional)
                  </span>
                  <Select
                    className="signUpSelectBox"
                    classNamePrefix="signUpSelectBoxPrefix"
                    placeholder={"Select one"}
                    isSearchable={false}
                    options={options}
                    onChange={handleChange}
                  />
                </div> */}
                {/* <div
                  className={` wrap-input100 validate-input ${
                    errors.referralValue && "border-error"
                  }`}
                >
                  <input
                    className="input100 has-val"
                    type={"text"}
                    name="referralValue"
                    defaultValue={referralValue}
                    onChange={(e) => referralChangeHandler(e)}
                    onBlur={(e) => handleBlur(e)}
                    {...register("referralValue")}
                  />
                  <span
                    className="focus-input100"
                    data-placeholder={"Referral Code (optional)"}
                  ></span>
                </div>
                {errors.referralValue && (
                  <span className="error-text">
                    {errors.referralValue.message}
                  </span>
                )} */}
                <div className={"acceptTermsWrapper"}>
                  <div>
                    <span className={"acceptTermsText"}>
                      I have read and agree to the{" "}
                      <span onClick={() => setShowTerms(true)}>
                        terms of service
                      </span>
                    </span>

                    <input type={"checkbox"} {...register("terms")} />
                  </div>

                  {errors.terms && (
                    <span className="error-text">{errors.terms.message}</span>
                  )}
                </div>

                <Button
                  ButtonText={"Sign Up"}
                  className={"signup"}
                  loading={loading || googleLoading}
                />
                <GoogleSignUpButton
                  referralCode={referralValue}
                  setLoading={setGoogleLoading}
                />

                <div className="text-center p-t-115 signup-form">
                  <span className="txt1">Already have an account?</span>

                  <Link className="txt2" to="/auth/login">
                    Login Instead
                  </Link>
                </div>
              </form>
              {/* <div className="aws-logo">
                                <img src={awsLogo} alt="powered by aws" />
                            </div> */}
            </div>
          </div>
        </div>
      </div>
      <ReferralModal
        data={submitData}
        setReferral={setReferralValue}
        setShow={setShowReferralModal}
        show={showReferralModal}
        submit={onsubmit}
      />
      <Modal
        title="Terms & Conditions"
        open={showTerms}
        footer={false}
        onCancel={() => setShowTerms(false)}
      >
        <p>
          CryptoCrispy Market Forecast, does not provide personal investment or
          financial advice to individuals, or act as personal financial, legal,
          or institutional investment advisors, or individually advocate the
          purchase or sale of any security or investment or the use of any
          particular financial strategy. All investing, stock forecasts, and
          investment strategies include the risk of loss for some or even all of
          your capital. Before pursuing any financial strategies discussed on
          this website, you should always consult with a licensed financial
          advisor.
        </p>
      </Modal>
    </>
  );
}

export default SignUpPage;
