export const servicesCost = {
  myais: 50,
  alert: 10,
  mybot: 25,
  insai: 80,
  trsim: 40,
  subscription: 19.99,
  subscription_aiSignal: 149.99,
  yearly_discount: 30,
  referral_credit: 2,
  referral_earn_percentage: 10,
  account_create_credit: 5,
  aiSignal: 60,
  myais_t3: 5,
  alert_t3: 1,
  mybot_t3: 2,
  insai_t3: 8,
  trsim_t3: 4,
  subscription_t3: 1.99,
  subscription_aiSignal_t3: 14.99,
  myais_t2: 25,
  alert_t2: 5,
  mybot_t2: 12,
  insai_t2: 40,
  trsim_t2: 20,
  subscription_t2: 9.99,
  subscription_aiSignal_t2: 74.99,
};

export const servicesActivationMode = {
  // 'alert'
  // 'trsim'
  // aiSignal
  // 'insai
  // 'mybot'
  // 'myais'
  aiSignal: "myais_alert_insai_trsim",
  tradeSimulator: "trsim",
  insai: "insai",
  insights: "insai",
  bot: "mybot",
  myais: "myais",
  alert: "alert",
  mybot: "mybot",
  miniAppAiSignals: "myais_alert",
};

export const renderServiceActivationMode = (service, plusAlert = false) => {
  const serviceName = service?.split("_")?.[0];
  return `activate_${servicesActivationMode[serviceName]}${
    plusAlert ? "_alert" : ""
  }`;
};
