import PlanCard from "./PlanCard";
import PlanTitle from "./PlanTitle";
import styles from "../../../styles/Components/pricing-plan/plans/Plan.module.css";
import { useSelector } from "react-redux";
import { useState } from "react";
import PlanDurationTabs from "./PlanDurationTabs";
import PlanCardBtn from "./PlanCardBtn";
import PlanFeaturesList from "./PlanFeaturesList";
import StripeCheckoutModal from "../StripeCheckoutModal";
import useSubscriptionPlans from "../../../hooks/useSubscriptionPlans";
import useUserTier from "../../../hooks/useUserTier";

function ProPlan() {
  const {
    pricingPlan: { pricingPlan },
  } = useSelector((state) => state);

  const { hasActivePro } = useSubscriptionPlans();

  const [planType, setPlanType] = useState("monthly");
  const [showPayment, setShowPayment] = useState(false);

  const { serviceType: subscription } = useUserTier("subscription_aiSignal");

  const offPrice = (
    (1 - pricingPlan["yearly_discount"] / 100) *
    pricingPlan[subscription]
  ).toFixed(2);

  const prices = {
    Monthly: pricingPlan[subscription],
    Yearly: offPrice * 12,
    Daily: 15,
  };

  return (
    <PlanCard>
      <PlanTitle type={"pro"} />

      <div className={styles.description}>
        <div className={styles.costInfo}>
          Get started with only
          <br />
          <div className={styles.costValue}>
            <span className={`${planType !== "monthly" ? styles.off : ""}`}>
              {pricingPlan[subscription]}
            </span>{" "}
            {planType !== "monthly" && <span>({offPrice})</span>} USD
          </div>
          / month
        </div>
      </div>
      <PlanCardBtn
        clickHandler={() => setShowPayment(true)}
        type={hasActivePro ? "change" : "buy"}
      >
        {hasActivePro ? "Change It" : "Buy It"}
      </PlanCardBtn>

      <PlanDurationTabs duration={planType} setDuration={setPlanType} />
      <div className={styles.featureBox}>
        <span className={styles.featureBoxTitle}>Features you'll love:</span>
        <PlanFeaturesList plan={"pro"} />
      </div>

      {showPayment && (
        <StripeCheckoutModal
          planType="pro"
          show={showPayment}
          setShow={setShowPayment}
          prices={prices}
        />
      )}
    </PlanCard>
  );
}

export default ProPlan;
