import React, { useEffect, useRef, useState } from "react";
import styles from "../../styles/shared/PricingDiscountButton.module.css";
import { FaExclamationCircle } from "react-icons/fa";
import { isMobile, isTablet } from "react-device-detect";
import { message, Popover, QRCode, Spin } from "antd";
import useGeoLocation from "../../hooks/useGeolocation";
import { useDispatch, useSelector } from "react-redux";
import { getProfileData } from "../../redux/profile/actions";
import useUserTier from "../../hooks/useUserTier";

const Text = ({ onClick }) => {
  const clickHandler = () => {
    if (onClick) {
      onClick();
    } else {
      console.log("clickHandler");
    }
  };

  return (
    <div
      className={styles.container}
      style={{ marginBottom: "1rem" }}
      onClick={clickHandler}
    >
      <FaExclamationCircle
        className={styles.attentionIcon}
        size={20}
        color="#0C4CFC"
      />
      <span className={styles.text}>
        Are you eligible for a low-income country discount?
      </span>
    </div>
  );
};

function PricingDiscount() {
  const {
    main: { userData },
    profile: { profileData },
    alertSystem: { telegramBotToken, telegramBotTokenStatus },
  } = useSelector((state) => state);

  const [popoverVisible, setPopoverVisible] = useState(false);
  const intervalRef = useRef(null); // Store the interval ID

  const isNotDesktop = isMobile || isTablet;
  const dispatch = useDispatch();

  const qrValue = `https://app-responsive.cryptocrispy.com.au/verify-location?user=${
    userData?.["custom:custom_username"]
  }&email=${userData?.email}&token=${telegramBotToken}`;

  const { canTry } = useUserTier();

  const { getLocation } = useGeoLocation();

  const handleVisibleChange = (visible) => {
    setPopoverVisible(visible);
    if (!visible) {
      message.destroy(); // Destroy message when popover closes
    }
  };

  useEffect(() => {
    if (popoverVisible && !canTry) {
      intervalRef.current = setInterval(() => {
        dispatch(
          getProfileData(userData?.email, userData?.["custom:custom_username"]),
        );
      }, 5000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [canTry, dispatch, popoverVisible, userData?.email]);

  useEffect(() => {
    if (popoverVisible) {
      if (!canTry) {
        message.success("User location verified successfully.", 5);
        setPopoverVisible(false);
      } else {
        message.loading({
          content: "Checking...",
          key: "checking-message", // Unique key for the message
          duration: 60,
        });
      }
    }
  }, [canTry, popoverVisible]);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      message.destroy(); // Cleanup messages on unmount
    };
  }, []);

  return (
    <>
      {isNotDesktop ? (
        <Text onClick={getLocation} />
      ) : (
        <Popover
          open={popoverVisible}
          onOpenChange={handleVisibleChange}
          mouseLeaveDelay={60}
          placement="bottomLeft"
          content={
            telegramBotTokenStatus === "loading" ? (
              <div className={styles.loader}>
                <Spin />
              </div>
            ) : (
              <QRCode value={qrValue} size={200} level="H" />
            )
          }
          title="Scan QR code using your mobile device to log in, and complete the action there."
        >
          <Text onClick={() => setPopoverVisible(!popoverVisible)} />
        </Popover>
      )}
    </>
  );
}

export default PricingDiscount;
