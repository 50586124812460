import styles from "../../../styles/Components/mini-app/mini-bots/NoBotBox.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RiLockFill } from "react-icons/ri";
import React, { useEffect, useState } from "react";
import ServiceActivationModal from "../../../shared/service-activation/ServiceActivationModal";
import { Button } from "antd";
import {
  getExchangePortfolio,
  getIntegratedExchanges,
  integrateWithExchange,
} from "../../../redux/exchange/actions";
import MiniAppExchangeInfo from "./MiniAppExchangeInfo";

function NoBotBox() {
  const {
    main: { userData },
    profile: { profileData },
    exchangeState: {
      integrateExchangeLoading,
      integratedDataLoading,
      getIntegratedList,
      integratedData,
      portfolioLoading,
    },
  } = useSelector((state) => state);

  const isServiceActive = profileData?.mybot_active === "True";

  const [showActivationModal, setShowActivationModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const integratedWithTestExchange = integratedData?.find(
    (ex) => ex.id === "cryptocrispy",
  );

  const startBotHandler = () => {
    if (!isServiceActive) {
      setShowActivationModal(true);
    } else {
      navigate("/mini-create-bot");
    }
  };

  const integrateHandler = () => {
    if (integrateExchangeLoading) return;
    dispatch(
      integrateWithExchange(
        userData["custom:custom_username"],
        userData?.email,
        "cryptocrispy",
        "cryptocrispy",
        "cryptocrispy",
        "cryptocrispy",
        "",
        "CryptoCrispy",
        true,
        true,
      ),
    );
  };

  useEffect(() => {
    if (
      Object.keys(userData).length &&
      !integratedDataLoading &&
      !getIntegratedList
    ) {
      dispatch(
        getIntegratedExchanges(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
        ),
      );
    }
  }, [dispatch, integratedDataLoading, userData, getIntegratedList]);

  // get test exchange portfolio if it has been integrated
  useEffect(() => {
    if (integratedWithTestExchange && userData?.email) {
      dispatch(
        getExchangePortfolio(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          "cryptocrispy",
        ),
      );
    }
  }, [dispatch, integratedWithTestExchange, userData]);

  return (
    <div className={styles.container}>
      <div className={styles.startBtn} onClick={startBotHandler}>
        {!isServiceActive && <RiLockFill />} Start Your Trading Adventure
      </div>
      <p>
        You can try Test exchange if you have no exchange or you wanna learn to
        try.
      </p>

      {!integratedWithTestExchange &&
        !portfolioLoading &&
        !integratedDataLoading && (
          <Button
            className={styles.integrateBtn}
            loading={integrateExchangeLoading}
            disabled={integrateExchangeLoading}
            onClick={integrateHandler}
          >
            Try Test exchange
          </Button>
        )}

      {integratedWithTestExchange && <MiniAppExchangeInfo />}

      {showActivationModal && (
        <ServiceActivationModal
          service={"mybot"}
          show={showActivationModal}
          setShow={setShowActivationModal}
        />
      )}
    </div>
  );
}

export default NoBotBox;
