import { FaDollarSign, FaPercent } from "react-icons/fa6";
import React from "react";
import styles from "../../../styles/Components/new-insight/watchlist/DisplayModeSwitcher.module.css";
import { useSearchParams } from "react-router-dom";

function DisplayModeSwitcher() {
  const [searchParams, setSearchParams] = useSearchParams();
  const percentValue = searchParams.get("isPercent") || true;
  const isPercent = percentValue === "true" ? true : percentValue !== "false";

  const setIsPercent = (val) => {
    searchParams.set("isPercent", val);
    setSearchParams(searchParams);
  };

  return (
    <div className={styles.container}>
      <span className={styles.title}>Changes unit:</span>
      <span
        className={`${styles.btn} ${isPercent ? styles.active : ""}`}
        onClick={() => setIsPercent(true)}
      >
        <FaPercent size={14} />
      </span>

      <span
        className={`${styles.btn} ${!isPercent ? styles.active : ""}`}
        onClick={() => setIsPercent(false)}
      >
        <FaDollarSign size={14} />
      </span>
    </div>
  );
}

export default DisplayModeSwitcher;
