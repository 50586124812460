import {
  ADD_TO_VIRTUAL_PORTFOLIO,
  GET_TRADE_AMOUNT,
  GET_TRADE_PORTFOLIO_ERROR,
  GET_TRADE_PORTFOLIO_REQUEST,
  GET_TRADE_PORTFOLIO_SUCCESS,
  GET_TRIGGER_PRICE,
  GET_VIRTUAL_ASSET_LIST_ERROR,
  GET_VIRTUAL_ASSET_LIST_REQUEST,
  GET_VIRTUAL_ASSET_LIST_SUCCESS,
  GET_VIRTUAL_PORTFOLIO_ERROR,
  GET_VIRTUAL_PORTFOLIO_REQUEST,
  GET_VIRTUAL_PORTFOLIO_SUCCESS,
  RESET_TRADE,
  RESET_VIRTUAL_TRADE_STATE,
  SET_ASSET,
  SET_ASSET_AMOUNT,
  SET_ASSET_AMOUNT_USD,
  SET_BASIC_TRADE_INFO,
  SET_BUY_LIMIT,
  SET_EXCHANGE,
  SET_MODAL_MODE,
  SET_SECOND_TRADE_TYPE,
  SET_SELL_LIMIT,
  SET_STEP,
  SET_STOP_LOSS,
  SET_STOP_LOSS_AMOUNT,
  SET_STOP_LOSS_AMOUNT_PERCENT,
  SET_STOP_LOSS_AMOUNT_USD,
  SET_STOP_LOSS_ORDER_ERROR,
  SET_STOP_LOSS_ORDER_REQUEST,
  SET_STOP_LOSS_ORDER_SUCCESS,
  SET_STOP_LOSS_TRIGGER_PRICE,
  SET_STOP_LOSS_TRIGGER_PRICE_PERCENT,
  SET_TAKE_PROFIT,
  SET_TAKE_PROFIT_AMOUNT,
  SET_TAKE_PROFIT_AMOUNT_PERCENT,
  SET_TAKE_PROFIT_AMOUNT_USD,
  SET_TAKE_PROFIT_ORDER_ERROR,
  SET_TAKE_PROFIT_ORDER_REQUEST,
  SET_TAKE_PROFIT_ORDER_SUCCESS,
  SET_TAKE_PROFIT_TRIGGER_PRICE,
  SET_TAKE_PROFIT_TRIGGER_PRICE_PERCENT,
  SET_TARGET_PRICE,
  SET_TRADE_AMOUNT_USD,
  SET_TRADE_PROCESS,
  SET_TRADE_TYPE,
  SET_USDT_AMOUNT,
  SET_VIRTUAL_ASSET,
  SET_VIRTUAL_ASSET_AMOUNT_NATIVE,
  SET_VIRTUAL_MARKET_TYPE,
  SET_VIRTUAL_PORTFOLIO,
  SET_VIRTUAL_REMAINING_BUDGET,
  SET_VIRTUAL_SECOND_TRADE_TYPE,
  SET_VIRTUAL_TRADE_AMOUNT,
  SET_VIRTUAL_TRADE_AMOUNT_PERCENT,
  SET_VIRTUAL_TRADE_AMOUNT_USD,
  SET_VIRTUAL_TRADE_TYPE,
  SET_VIRTUAL_TRIGGER_PRICE,
} from "./const";
import axios from "axios";
import {
  errorHandler,
  expiredTokenHandler,
  networkErrorHandler,
  timeOutErrorHandler,
} from "../../utility/helper";
import { getExchangePortfolio } from "../exchange/actions";
import { axiosErrorHandler } from "../../utility/action-helper";
import { GET_FAST_DASHBOARD_DATA_ERROR } from "../dashboard/consts";

const baseUrl =
  "https://ydq39f2t8b.execute-api.ap-southeast-2.amazonaws.com/dev/api";
export const setExchange = (exchange) => {
  return { type: SET_EXCHANGE, payload: exchange };
};

export const setAsset = (asset) => {
  return { type: SET_ASSET, payload: asset };
};

export const setTradeType = (type) => {
  return {
    type: SET_TRADE_TYPE,
    payload: type,
  };
};

export const setMode = (mode) => {
  return { type: SET_MODAL_MODE, payload: mode };
};

export const setSecondTradeType = (type) => {
  return { type: SET_SECOND_TRADE_TYPE, payload: type };
};

export const setStep = (step) => {
  return { type: SET_STEP, payload: step };
};

export const resetTradeData = () => {
  return { type: RESET_TRADE };
};

export const setTradeAmount = (amount) => {
  return { type: GET_TRADE_AMOUNT, payload: amount };
};

export const setTradeAmountUsd = (amount) => {
  return { type: SET_TRADE_AMOUNT_USD, payload: amount };
};

export const setTriggerPrice = (price) => {
  return { type: GET_TRIGGER_PRICE, payload: price };
};

export const getTradePortfolio =
  (
    user,
    email,
    exchange_id,
    usd_balance_calculation = "True",
    retryCount = 0,
  ) =>
  async (dispatch) => {
    dispatch({ type: GET_TRADE_PORTFOLIO_REQUEST });

    const data = {
      mode: "get_portfolio",
      user,
      email,
      exchange_id,
      usd_balance_calculation,
    };

    if (retryCount > 0) {
      data.use_more_caching = "True";
    }

    await axios
      .post(baseUrl, data)
      .then((res) => {
        dispatch({
          type: GET_TRADE_PORTFOLIO_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            getTradePortfolio(
              user,
              email,
              exchange_id,
              (usd_balance_calculation = "False"),
              retryCount + 1,
            ),
          GET_TRADE_PORTFOLIO_ERROR,
          user,
          email,
          "get_portfolio",
        );
        // if (err?.message === "Network ErrorComponent") {
        //   if (retryCount < 2) {
        //     dispatch(
        //       getTradePortfolio(
        //         user,
        //         email,
        //         exchange_id,
        //         (usd_balance_calculation = "False"),
        //         retryCount + 1,
        //       ),
        //     );
        //   } else {
        //     networkErrorHandler();
        //     dispatch({
        //       type: GET_TRADE_PORTFOLIO_ERROR,
        //       payload: "Network error",
        //     });
        //   }
        // }
        // const { STATUS_CODE, MESSAGE } = err?.response.data;
        // if (STATUS_CODE === 454 && retryCount < 2) {
        //   dispatch(
        //     getTradePortfolio(
        //       user,
        //       email,
        //       exchange_id,
        //       (usd_balance_calculation = "False"),
        //       retryCount + 1,
        //     ),
        //   );
        // } else {
        //   timeOutErrorHandler();
        // }
        // dispatch({ type: GET_TRADE_PORTFOLIO_ERROR, payload: MESSAGE });
        // if (STATUS_CODE === 450) {
        //   expiredTokenHandler(MESSAGE);
        // }
      });
  };

export const setUsdtAmount = (usdtAmount) => {
  return { type: SET_USDT_AMOUNT, payload: usdtAmount };
};

export const setAssetAmount = (amount) => {
  return { type: SET_ASSET_AMOUNT, payload: amount };
};

export const setAssetAmountUsd = (amount) => {
  return { type: SET_ASSET_AMOUNT_USD, payload: amount };
};

export const setVirtualSelectedAsset = (coin) => {
  return { type: SET_VIRTUAL_ASSET, payload: coin };
};

export const setVirtualSelectedPortfolio = (portfolio) => {
  return { type: SET_VIRTUAL_PORTFOLIO, payload: portfolio };
};

export const setVirtualRemainingBudget = (budget) => {
  return {
    type: SET_VIRTUAL_REMAINING_BUDGET,
    payload: budget,
  };
};

export const setVirtualTradeType = (type) => {
  return {
    type: SET_VIRTUAL_TRADE_TYPE,
    payload: type,
  };
};

export const setVirtualSecondTradeType = (type) => {
  return {
    type: SET_VIRTUAL_SECOND_TRADE_TYPE,
    payload: type,
  };
};

export const setVirtualTradeAmount = (amount) => {
  return { type: SET_VIRTUAL_TRADE_AMOUNT, payload: amount };
};
export const setVirtualTradeAmountPercent = (percent) => {
  return { type: SET_VIRTUAL_TRADE_AMOUNT_PERCENT, payload: percent };
};
export const setVirtualTradeAmountUsd = (amount) => {
  return { type: SET_VIRTUAL_TRADE_AMOUNT_USD, payload: amount };
};
export const setVirtualTriggerPrice = (value, percent) => {
  return { type: SET_VIRTUAL_TRIGGER_PRICE, payload: { value, percent } };
};
export const resetVirtualTradeState = () => {
  return { type: RESET_VIRTUAL_TRADE_STATE };
};
export const setTradeProcess = (type) => {
  return { type: SET_TRADE_PROCESS, payload: type };
};

export const setVirtualMarketType = (type) => {
  return {
    type: SET_VIRTUAL_MARKET_TYPE,
    payload: type,
  };
};

export const setVirtualAssetAmount = (amount) => {
  return { type: SET_VIRTUAL_ASSET_AMOUNT_NATIVE, payload: amount };
};

export const getVirtualPortfolio =
  (email, user, portfolio_type, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_VIRTUAL_PORTFOLIO_REQUEST });

    await axios
      .post(
        "https://tgh7aum9h0.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        {
          platform_mode: "trade_assistant",
          email: email,
          user: user,
          request_type: "get_portfolio",
          portfolio_type,
        },
      )
      .then((res) => {
        dispatch({ type: GET_VIRTUAL_PORTFOLIO_SUCCESS, payload: res?.data });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            getVirtualPortfolio(email, user, portfolio_type, retryCount + 1),
          GET_VIRTUAL_PORTFOLIO_ERROR,
          user,
          email,
          "get_portfolio",
        );
      });
  };

export const getVirtualAssetList =
  (email, user, portfolio_type, retryCount = 0) =>
  async (dispatch) => {
    dispatch({ type: GET_VIRTUAL_ASSET_LIST_REQUEST });

    await axios
      .post(
        "https://hav8w1xk8h.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        {
          platform_mode: "trade_assistant",
          email: email,
          user: user,
          request_type: "get_asset_list",
          portfolio_type: portfolio_type.toLowerCase(),
        },
      )
      .then((res) => {
        dispatch({
          type: GET_VIRTUAL_ASSET_LIST_SUCCESS,
          payload: res?.data?.assets,
        });
      })
      .catch((err) => {
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            getVirtualAssetList(email, user, portfolio_type, retryCount + 1),
          GET_VIRTUAL_ASSET_LIST_ERROR,
          user,
          email,
          "get_asset_list",
        );
      });
  };

export const setStopLossOrder =
  (
    user,
    email,
    exchange_id,
    asset,
    trade_type,
    amount,
    trigger_price,
    currency,
    retryCount = 0,
  ) =>
  async (dispatch) => {
    dispatch({ type: SET_STOP_LOSS_ORDER_REQUEST });

    await axios
      .post(
        "https://ydq39f2t8b.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        {
          mode: "order_trade",
          user,
          email,
          exchange_id,
          asset,
          trade_type,
          amount,
          trigger_price,
          currency,
        },
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: SET_STOP_LOSS_ORDER_SUCCESS, payload: res.data });
          dispatch(handleStopLossSelection());
        } else {
          dispatch({
            type: SET_STOP_LOSS_ORDER_ERROR,
            payload: res.response.data,
          });
        }
      })
      .catch((err) => {
        // const { STATUS_CODE, MESSAGE } = err?.response.data;
        // if (MESSAGE) {
        //   dispatch({ type: SET_STOP_LOSS_ORDER_ERROR, payload: MESSAGE });
        //   errorHandler(STATUS_CODE, MESSAGE);
        // }
        if (err.response.status === 421) {
          dispatch({
            type: SET_STOP_LOSS_ORDER_ERROR,
            payload: err.response.data,
          });
          return;
        }
        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            setStopLossOrder(
              user,
              email,
              exchange_id,
              asset,
              trade_type,
              amount,
              trigger_price,
              currency,
              retryCount + 1,
            ),
          SET_STOP_LOSS_ORDER_ERROR,
          user,
          email,
          "order_trade",
        );
      });
  };

export const setTakeProfitOrder =
  (
    user,
    email,
    exchange_id,
    asset,
    trade_type,
    amount,
    trigger_price,
    currency,
    retryCount = 0,
  ) =>
  async (dispatch) => {
    dispatch({ type: SET_TAKE_PROFIT_ORDER_REQUEST });

    await axios
      .post(
        "https://ydq39f2t8b.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        {
          mode: "order_trade",
          user,
          email,
          exchange_id,
          asset,
          trade_type,
          amount,
          trigger_price,
          currency,
        },
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch({ type: SET_TAKE_PROFIT_ORDER_SUCCESS, payload: res.data });
          dispatch(handleTakeProfitSelection());
        } else {
          dispatch({
            type: SET_TAKE_PROFIT_ORDER_ERROR,
            payload: res.response.data,
          });
        }
      })
      .catch((err) => {
        // const { STATUS_CODE, MESSAGE } = err?.response.data;
        // if (MESSAGE) {
        //   dispatch({ type: SET_TAKE_PROFIT_ORDER_ERROR, payload: MESSAGE });
        //   errorHandler(STATUS_CODE, MESSAGE);
        // }
        if (err.response.status === 421) {
          dispatch({
            type: SET_TAKE_PROFIT_ORDER_ERROR,
            payload: err.response.data,
          });
          return;
        }

        axiosErrorHandler(
          err,
          retryCount,
          dispatch,
          () =>
            setTakeProfitOrder(
              user,
              email,
              exchange_id,
              asset,
              trade_type,
              amount,
              trigger_price,
              currency,
              retryCount + 1,
            ),
          SET_TAKE_PROFIT_ORDER_ERROR,
          user,
          email,
          "order_trade",
        );
      });
  };

export const setStopLossTriggerPrice = (price) => {
  return {
    type: SET_STOP_LOSS_TRIGGER_PRICE,
    payload: price,
  };
};

export const setTakeProfitTriggerPrice = (price) => {
  return {
    type: SET_TAKE_PROFIT_TRIGGER_PRICE,
    payload: price,
  };
};

export const handleStopLossSelection = (status) => {
  return { type: SET_STOP_LOSS, payload: status };
};

export const handleTakeProfitSelection = (status) => {
  return { type: SET_TAKE_PROFIT, payload: status };
};

export const setStopLossAmountPercent = (percent) => {
  return { type: SET_STOP_LOSS_AMOUNT_PERCENT, payload: percent };
};

export const setTakeProfitAmountPercent = (percent) => {
  return { type: SET_TAKE_PROFIT_AMOUNT_PERCENT, payload: percent };
};

export const setStopLossAmount = (amount) => {
  return { type: SET_STOP_LOSS_AMOUNT, payload: amount };
};

export const setTakeProfitAmount = (amount) => {
  return { type: SET_TAKE_PROFIT_AMOUNT, payload: amount };
};

export const setTakeProfitUsdAmount = (amount) => {
  return { type: SET_TAKE_PROFIT_AMOUNT_USD, payload: amount };
};

export const setStopLossUsdAmount = (amount) => {
  return { type: SET_STOP_LOSS_AMOUNT_USD, payload: amount };
};

export const setStopLossTriggerPricePercent = (percent) => {
  return { type: SET_STOP_LOSS_TRIGGER_PRICE_PERCENT, payload: percent };
};

export const setTakeProfitTriggerPricePercent = (percent) => {
  return { type: SET_TAKE_PROFIT_TRIGGER_PRICE_PERCENT, payload: percent };
};

export const setBasicTradeInfo = (info) => {
  return { type: SET_BASIC_TRADE_INFO, payload: info };
};

export const setBuyLimit = () => {
  return { type: SET_BUY_LIMIT };
};

export const setSellLimit = () => {
  return { type: SET_SELL_LIMIT };
};

export const setTargetPrice = (price) => {
  return { type: SET_TARGET_PRICE, payload: price };
};

export const addToVirtualPortfolio = () => {
  return { type: ADD_TO_VIRTUAL_PORTFOLIO };
};
