import styles from "../../styles/shared/service-activation/ActivateServiceByCrypto.module.css";
import { Slider } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { calculateOffPrice } from "../../utility/helper";
import { servicesCost } from "../../utility/service-activation";

function ActivationDurationOptions({
  activationType,
  service,
  selectedDuration,
  setSelectedDuration,
  additionalCost,
}) {
  const {
    profile: { profileData },
    pricingPlan: { pricingPlan },
  } = useSelector((state) => state);

  const [showSlider, setShowSlider] = useState(false);

  const offPercentage =
    pricingPlan["yearly_discount"] || servicesCost["yearly_discount"];

  const cost = (pricingPlan[service] || servicesCost[service]) + additionalCost;
  const noLimitForDuration = activationType === "payment";
  const maximumDurationForCredit = Math.trunc(profileData?.credit / 100 / cost);

  const sliderFormatter = (value) => `${value} Month${value > 1 ? "s" : ""}`;

  const selectDurationHandler = (e) => {
    if (showSlider) setShowSlider(false);
    const parentEl = e.target.closest("div");
    if (parentEl) {
      setSelectedDuration(+parentEl.id);
    }
  };

  const showSliderHandler = () => {
    if (showSlider) return;
    if (selectedDuration) {
      setSelectedDuration(null);
    }

    setShowSlider(true);
  };

  return (
    <>
      <div className={styles.options}>
        <div
          className={`${styles.option} ${
            !showSlider && selectedDuration === 1 ? styles.selectedOption : ""
          }`}
          id={"1"}
          onClick={selectDurationHandler}
        >
          <span className={styles.optionTitle}>1 Month</span>
          <span className={styles.optionCost}>${cost}</span>
        </div>

        {(noLimitForDuration || maximumDurationForCredit >= 3) && (
          <div
            className={`${styles.option} ${
              !showSlider && selectedDuration === 3 ? styles.selectedOption : ""
            }`}
            id={"3"}
            onClick={selectDurationHandler}
          >
            <span className={styles.off}>
              {calculateOffPrice(offPercentage, cost, 3, "off")}%
            </span>
            <span className={styles.optionTitle}>3 Months</span>
            <span className={styles.optionCost}>
              ${calculateOffPrice(offPercentage, cost, 3, "price")}
            </span>
          </div>
        )}
        {(noLimitForDuration || maximumDurationForCredit >= 6) && (
          <div
            className={`${styles.option} ${
              !showSlider && selectedDuration === 6 ? styles.selectedOption : ""
            }`}
            id={"6"}
            onClick={selectDurationHandler}
          >
            <span className={styles.off}>
              {calculateOffPrice(offPercentage, cost, 6, "off")}%
            </span>
            <span className={styles.optionTitle}>6 Months</span>
            <span className={styles.optionCost}>
              ${calculateOffPrice(offPercentage, cost, 6, "price")}
            </span>
          </div>
        )}
        {(noLimitForDuration || maximumDurationForCredit >= 12) && (
          <div
            className={`${styles.option} ${
              !showSlider && selectedDuration === 12
                ? styles.selectedOption
                : ""
            }`}
            id={"12"}
            onClick={selectDurationHandler}
          >
            <span className={styles.off}>
              {calculateOffPrice(offPercentage, cost, 12, "off")}%
            </span>
            <span className={styles.optionTitle}>1 Year</span>
            <span className={styles.optionCost}>
              ${calculateOffPrice(offPercentage, cost, 12, "price")}
            </span>
          </div>
        )}
        <div
          className={`${styles.option} ${
            showSlider ? styles.selectedOption : ""
          }`}
          onClick={showSliderHandler}
        >
          Custom
        </div>
      </div>
      {showSlider && (
        <div className={styles.customDurationBox}>
          <Slider
            min={1}
            max={
              noLimitForDuration ? 12 : Math.min(maximumDurationForCredit, 12)
            }
            onChange={(value) => setSelectedDuration(value)}
            value={selectedDuration || 1}
            tipFormatter={sliderFormatter}
            tooltipVisible
          />
          {selectedDuration && (
            <>
              {selectedDuration > 1 && (
                <h3>
                  Activation Length: {selectedDuration} month(s)
                  <br />
                  Discount: $
                  {calculateOffPrice(
                    offPercentage,
                    cost,
                    selectedDuration,
                    "offeredPrice",
                  ).toFixed(2)}{" "}
                  (
                  {calculateOffPrice(
                    offPercentage,
                    cost,
                    selectedDuration,
                    "off",
                  )}
                  %)
                  <br />
                  Price After Discount: $
                  {calculateOffPrice(
                    offPercentage,
                    cost,
                    selectedDuration,
                    "price",
                  )}
                </h3>
              )}
              {/*{selectedDuration === 1 && <h3>1 month: ${cost}</h3>}*/}
            </>
          )}
        </div>
      )}
    </>
  );
}

export default ActivationDurationOptions;
