import styles from "../../styles/Components/sidebar/SideBarMoreMenu.module.css";
import { useLocation } from "react-router";
import helpIcon from "../../asset/Icons/icon-14.svg";
import apiIcon from "../../asset/Icons/icon-09.svg";
import pricingIcon from "../../asset/Icons/icon-13.svg";
import tradeSimulatorIcon from "../../asset/Icons/icon-18.svg";
import { Link } from "react-router-dom";

const ITEMS_DATA = [
  {
    label: "Trade Simulator",
    link: "trade-simulator",
    icon: tradeSimulatorIcon,
  },
  // {
  //   label: "Insights",
  //   link: "insight",
  //   icon: insightIcon,
  // },
  {
    label: "Developer API",
    link: "developer-api",
    icon: apiIcon,
  },
  {
    label: "Pricing & Billing",
    link: "pricing-billing",
    icon: pricingIcon,
  },
  {
    label: "Help & Feedback",
    link: "faq/all",
    icon: helpIcon,
  },
];

const MenuItem = ({ item }) => {
  const location = useLocation();
  const pathName = location?.pathname?.split("/")[1];

  const { label, icon, link } = item;

  const isBeta = label === "Trade Simulator" || label === "Insights";

  return (
    <li
      className={`${pathName === link.split("/")[0] ? styles.active : ""} ${
        isBeta ? styles.beta : ""
      }`}
    >
      <Link to={"/" + link}>
        <img className={styles.itemIcon} src={icon} alt={label} />
        <span>{label}</span>
      </Link>
    </li>
  );
};

function SideBarMoreMenu({ className, closeMenu }) {
  return (
    <ul className={`${className} ${styles.container}`}>
      {/*<li onClick={closeMenu}>*/}
      {/*  <IoClose />*/}
      {/*</li>*/}
      {ITEMS_DATA.map((item) => (
        <MenuItem item={item} key={item?.label} />
      ))}
      {/*<li className={`${pathName === "trade-simulator" && styles.active}`}>*/}
      {/*  Trade Simulator*/}
      {/*</li>*/}
      {/*<li>Insights</li>*/}
      {/*<li>Developer API</li>*/}
      {/*<li>Pricing & Billing</li>*/}
      {/*<li>Help & Feedback</li>*/}
    </ul>
  );
}

export default SideBarMoreMenu;
