import { Button, Modal, Radio } from "antd";
import { BsCaretRightFill } from "react-icons/bs";
import React, { useState } from "react";
import styles from "../../../styles/shared/service-activation/ServiceActivationPaymentMethods.module.css";
import { SiBitcoinsv } from "react-icons/si";
import { FaRegCreditCard } from "react-icons/fa";
import DisplayActivationWithPaymentMethodsResponse from "../../../shared/service-activation/DisplayActivationWithPaymentMethodsResponse";
import {
  activateServiceWithCard,
  activateServiceWithCrypto,
} from "../../../redux/pricing-plan/action";
import { renderServiceActivationMode } from "../../../utility/service-activation";
import { useDispatch, useSelector } from "react-redux";

function PaymentOptionsModal({ show, setShow, activationMode, amount }) {
  const {
    main: { userData },
  } = useSelector((state) => state);

  const [paymentMethod, setPaymentMethod] = useState("crypto");
  const [content, setContent] = useState("methods");

  const dispatch = useDispatch();

  const closeModal = () => {
    setShow(false);
  };

  const selectPaymentMethod = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handlePayment = () => {
    const redirectUrl = window.location.href;
    setContent("result");

    if (paymentMethod === "crypto") {
      dispatch(
        activateServiceWithCrypto(
          userData?.["custom:custom_username"],
          userData?.email,
          activationMode,
          `${amount}`,
          redirectUrl,
          redirectUrl,
        ),
      );
    }

    if (paymentMethod === "card") {
      dispatch(
        activateServiceWithCard(
          userData?.["custom:custom_username"],
          userData?.email,
          activationMode,
          `${amount}`,
          redirectUrl,
          redirectUrl,
        ),
      );
    }
  };

  return (
    <Modal open={show} onCancel={closeModal} centered footer={null}>
      {content === "methods" && (
        <>
          <h4>
            <BsCaretRightFill />
            Just choose how you'd like to pay:
          </h4>

          <Radio.Group
            className={styles.methods}
            value={paymentMethod}
            onChange={selectPaymentMethod}
          >
            <Radio value={"crypto"} className={styles.method}>
              <SiBitcoinsv />
              Crypto
            </Radio>
            <Radio value={"card"} className={styles.method}>
              <FaRegCreditCard /> Credit Card
            </Radio>
          </Radio.Group>

          <div className={styles.submitBtn}>
            <Button onClick={handlePayment}>Submit</Button>
          </div>
        </>
      )}

      {content === "result" && (
        <DisplayActivationWithPaymentMethodsResponse
          method={paymentMethod}
          changeContent={setContent}
        />
      )}
    </Modal>
  );
}

export default PaymentOptionsModal;
