import { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

const COUNTER = 10;

function DisplayRatingModal({
  page,
  condition = true,
  setVisitedPage,
  showRatingModal,
}) {
  const {
    profile: { profileData },
  } = useSelector((state) => state);

  const currentDate = moment();
  const canceledDate = profileData?.[`${page}_cancel_rate_date`];

  const canceledCondition =
    !canceledDate || currentDate.diff(moment(canceledDate), "months") >= 1;

  useEffect(() => {
    // Check all conditions upfront
    const shouldRunTimer =
      profileData?.pricing_plan &&
      !profileData?.[`${page}_rate`] &&
      profileData?.suggested_features &&
      canceledCondition &&
      condition;

    if (shouldRunTimer) {
      const timer = setTimeout(() => {
        showRatingModal(true);
        setVisitedPage(page);
      }, COUNTER * 1000);

      // Cleanup the timer
      return () => {
        clearTimeout(timer);
      };
    }
  }, [
    canceledCondition,
    condition,
    page,
    profileData,
    setVisitedPage,
    showRatingModal,
  ]);

  return null;
}

export default DisplayRatingModal;
