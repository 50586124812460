import { Modal, Input, Spin, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import styles from "../../../styles/pages/Auth/login/RecaptchaModal.module.css";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { checkEmailException } from "../../../utility/helper";
import PoolData from "../AWSConfig/PoolData";
import {
  getRecaptcha,
  setToken,
  setUserData,
} from "../../../redux/main/action/main";
import axios from "axios";
import { notify } from "../../../utility/Toastify/toastify";
import { useNavigate } from "react-router-dom";
import ConfirmAlert from "../ConfirmAlert";
import { TbReload } from "react-icons/tb";

function RecaptchaModal({ open, onClose, data }) {
  const { recaptchaData, recaptchaStatus } = useSelector(
    (state) => state?.main,
  );

  const [userInput, setUserInput] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [pass, setPass] = useState("");
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const refreshRecaptcha = (e) => {
    if (e) e.stopPropagation();
    dispatch(getRecaptcha());
    setError("");
    setUserInput("");
  };

  const closeModal = () => {
    if (loading) return;
    onClose();
    setUserInput("");
    setError("");
    setLoading(false);
    setUserEmail("");
    setPass("");
    setShowConfirmAlert(false);
    refreshRecaptcha();
  };

  const onSubmit = () => {
    if (+userInput !== recaptchaData.answer || loading) return;

    setLoading(true);
    setUserEmail(data?.email.toLowerCase());
    setPass(data?.pass);

    let user = new CognitoUser({
      Username: checkEmailException(data?.email),
      Pool: PoolData,
    });
    let authDetails = new AuthenticationDetails({
      Username: data?.email,
      Password: data?.pass,
      ValidationData: { captcha: "rtspa_mehdi_ravanbakhsh_761" },
    });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        dispatch(setToken(data?.idToken?.jwtToken));
        dispatch(setUserData(data?.idToken?.payload));
        axios.defaults.headers.common["Authorization"] =
          `Bearer ${data?.idToken?.jwtToken}`;
        // window.location.reload();
        // notify("You have successfully logged in", "success");
        localStorage.setItem(
          "aws-amplify-federatedInfo",
          JSON.stringify({ token: data?.idToken?.jwtToken }),
        );
        localStorage.setItem(
          "aws-amplify-USERDATA",
          JSON.stringify(data?.idToken?.payload),
        );
        setLoading(false);
        // navigate("/ai-assistant/assistant");
        navigate("/welcome");
      },

      onFailure: function (err) {
        setLoading(false);
        if (
          err.message?.includes("username") ||
          err.message?.includes("password")
        )
          closeModal();

        if (err?.message === "User is not confirmed.") {
          setShowConfirmAlert(true);
          return;
        }
        if (err?.message.includes("captcha")) return;
        notify(err?.message, "error");
      },

      newPasswordRequired: (data) => {
        notify(data, "success");
        setLoading(false);
      },
    });
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^-?\d*$/.test(value)) {
      setUserInput(value);
    }
  };

  const handleInputBlur = () => {
    const storedAnswer = localStorage.getItem("recAnswer");
    if (storedAnswer === userInput) {
      setError("");
      onSubmit();
    } else {
      setError("Please enter the correct answer.");
      // message.error("Please enter the correct answer.");
    }
  };

  const handleEnterKeyDown = (e) => {
    if (e.key === "Enter") {
      handleInputBlur();
    }
  };

  return (
    <Modal
      open={open}
      centered
      footer={null}
      onCancel={closeModal}
      title="Verify you are human"
      maskClosable={false}
      className={styles.modal}
    >
      {recaptchaStatus === "loading" && (
        <div style={{ textAlign: "center" }}>
          <Spin size="large" />
        </div>
      )}
      {recaptchaStatus === "error" && (
        <div style={{ textAlign: "center" }}>
          <h4>Something went wrong!</h4>
          <TbReload
            size={24}
            onClick={refreshRecaptcha}
            style={{ cursor: "pointer" }}
          />
        </div>
      )}
      {recaptchaStatus === "ready" && recaptchaData?.question && (
        <div className={styles.form}>
          <h4>{recaptchaData.question}</h4>
          <div className={styles.inputContainer}>
            <Input
              placeholder="Enter your answer"
              value={userInput}
              onChange={handleInputChange}
              onBlur={handleInputBlur} // Add the onBlur event here
              status={error ? "error" : ""}
              disabled={loading}
              onKeyDown={handleEnterKeyDown}
            />
            <TbReload
              onClick={refreshRecaptcha}
              className={styles.refreshBtn}
            />
          </div>
          <p className={styles.errorMessage}>{error}</p>
          <Button loading={loading} onClick={onSubmit}>
            Login
          </Button>
        </div>
      )}

      <ConfirmAlert
        show={showConfirmAlert}
        setShow={setShowConfirmAlert}
        email={userEmail}
        password={pass}
      />
    </Modal>
  );
}

export default RecaptchaModal;
