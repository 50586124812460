import React, { useEffect, useState } from "react";
import SideBar from "../sidebars/SideBar";
import styles from "../../styles/Components/layout/layout.module.sass";
import Header from "../Header";
import loader from "../../asset/Images/Pulse-1s-200px.svg";
import MultiLevelSideBar from "../sidebars/MultiLevelSideBar";
import { Button, Tabs } from "antd";
import { setLogout } from "../../redux/main/action/main";
import PoolData from "../../pages/Auth/AWSConfig/PoolData";
import { useDispatch, useSelector } from "react-redux";
import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Cookies from "universal-cookie";
import alert from "../alerts/Alert";
import Navbar from "./Navbar";
import TutorialButton from "./TutorialButton";
import GlobalErrorBubble from "../../shared/GlobalErrorBubble";

const insightTourConfig = [
  {
    selector: '[data-tut="reactour__copy"]',
    content: `Keep in mind that you could try and test everithing during the Tour.
      For example, try selecting the highlighted text…`,
  },
  {
    selector: '[data-tut="reactour__sideBar"]',
    content: `Keep in mind that you could try and test everithing during the Tour.
      For example, try selecting the highlighted text…`,
  },
  {
    selector: '[data-tut="reactour__test1"]',
    content: `Keep in mind that you could try and test everithing during the Tour.
      For example, try selecting the highlighted text…`,
  },
  {
    selector: '[data-tut="reactour__test2"]',
    content: `Keep in mind that you could try and test everithing during the Tour.
      For example, try selecting the highlighted text…`,
  },
  {
    selector: '[data-tut="reactour__chart"]',
    content: `Keep in mind that you could try and test everithing during the Tour.
      For example, try selecting the highlighted text…`,
  },
  {
    selector: '[data-tut="reactour__signalsRadio"]',
    content: `Keep in mind that you could try and test everithing during the Tour.
      For example, try selecting the highlighted text…`,
  },
  {
    selector: '[data-tut="reactour__singlaDetails"]',
    content: `Keep in mind that you could try and test everithing during the Tour.
      For example, try selecting the highlighted text…`,
  },
];

const DashboardLayout = ({
  children,
  insightLayout,
  loading,
  error,
  aiLayout,
  alertSystem,
  hasDemo,
}) => {
  const [showMultiLevelSideBar, setShowMultiLevelSideBar] = useState(true);
  const [errorPage, setErrorPage] = useState(false);
  const [toggle, setToggle] = useState(2);
  const dispatch = useDispatch();
  const {
    initialInfo: { CATEGORIES_FEATURE_TREE },
  } = useSelector((state) => state?.insightState);
  const items = [
    { label: "Menu", key: 1 },
    { label: "Features", key: 2 },
  ];
  const onChange = (key) => {
    setToggle(key);
  };

  const swal = (text) => {
    return alert(null, text, "warning", {
      cancel: "Close",
      defeat: {
        text: "Buy Plan",
        value: "buyPlan",
      },
    });
  };
  // let localToken = JSON.parse(
  //   localStorage.getItem("aws-amplify-federatedInfo")
  // )?.token;
  useEffect(() => {
    if (error?.response?.status == 401 || error?.status === 401) {
      dispatch(setLogout());
      const user = PoolData.getCurrentUser();
      user?.signOut();
      localStorage.clear();
      // window.location.reload(false);
      window.location.reload();
    }
    if (error?.response?.status == 504 || error?.status === 504) {
      setErrorPage(true);
    }
    if (Array.isArray(error?.RESPONSE_LIST)) {
      error?.RESPONSE_LIST?.map((item) => {
        switch (item?.STATUS_CODE) {
          case 401: {
            swal(
              "You have reached daily limit, for unlimited use, you should buy pro plan",
            );
            break;
          }
          // case 402: {
          //   swal("Your account is expired");
          //
          //   break;
          // }
          case 403: {
            swal("Insufficient credit");
            break;
          }
          case 410: {
            swal(
              "Check your connection! Your Internet does not seem to be stable",
            );
            break;
          }
          default: {
            return;
          }
        }
      });
    }
    if (error) {
      switch (error?.STATUS_CODE) {
        case "401": {
          swal(
            "You have reached daily limit, for unlimited use, you should buy pro plan",
          );
          break;
        }
        // case "402": {
        //   swal("Your account is expired");
        //
        //   break;
        // }
        case "403": {
          swal("Insufficient credit");
          break;
        }
        default: {
          return;
        }
      }
    }
  }, [error]);

  const [showTour, setShowTour] = useState(false);
  // const [showMoreTour, setShowMoreTour] = useState(true);
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  // const toggleShowMore = () => {
  //   setShowMoreTour(!showMoreTour);
  // };
  const cookies = new Cookies();
  const closeTour = () => {
    setShowTour(false);
    cookies.set("tour", true);
  };

  // const openTour = () => {
  //   setShowTour(true);
  // };
  const accentColor = "#5cb7b7";

  return (
    <>
      <div className={styles.container}>
        {insightLayout && (
          <div className={styles.switchSideBar}>
            <Tabs
              width={"100%"}
              defaultActiveKey={2}
              data-tut="reactour__test2"
              items={items}
              onChange={onChange}
            />
          </div>
        )}
        {(insightLayout && toggle === 2 && !loading) ||
        (insightLayout && toggle === 2 && CATEGORIES_FEATURE_TREE) ? (
          <MultiLevelSideBar
            setShowMultiLevelSideBar={setShowMultiLevelSideBar}
            showMultiLevelSideBar={showMultiLevelSideBar}
          />
        ) : (
          <SideBar insightLayout={insightLayout} />
        )}
        <div className={styles.mainAndHeaderContainer}>
          {/*<Header help={insightLayout} ai={aiLayout} alert={alertSystem} />*/}
          <Header />
          <GlobalErrorBubble />
          <div className={insightLayout ? styles.insightMain : styles.main}>
            {errorPage ? (
              <div className={styles.errorWrapper}>
                <h3>Something went wrong please refresh the page</h3>
                <Button onClick={() => window.location.reload()}>
                  Refresh
                </Button>
              </div>
            ) : (
              children
            )}
          </div>
          <Navbar />
        </div>
        <div
          className={`${styles.loadingContainer} ${
            loading ? styles.showLoading : ""
          }`}
        >
          <img src={loader} alt="loader" />
        </div>
        {hasDemo && <TutorialButton />}
      </div>

      {!loading && insightLayout && (
        <Tour
          onRequestClose={closeTour}
          steps={insightTourConfig}
          isOpen={showTour}
          maskClassName="mask"
          className="helper"
          rounded={5}
          accentColor={accentColor}
          onAfterOpen={disableBody}
          onBeforeClose={enableBody}
        />
      )}
    </>
  );
};

export default DashboardLayout;
