import { useState } from "react";
import ChargeCreditInput from "../../../shared/ChargeCreditInput";
import styles from "../../../styles/Components/mini-app/mini-pricing/MiniCreditCharge.module.css";
import { Button, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  chargeCreditWithCart,
  chargeCreditWithCrypto,
} from "../../../redux/pricing-plan/action";
import { FaRegCreditCard } from "react-icons/fa";
import { BsCurrencyBitcoin } from "react-icons/bs";
import RedeemGift from "../../../pages/pricing-plan/RedeemGift";
import { MdRedeem } from "react-icons/md";

function MiniCreditCharge({
  inputClassName,
  btnsClassName,
  redeemClassName,
  paymentBtnClassName,
  optionBtn,
  selectedOptionClassName,
}) {
  const {
    main: { userData },
    pricingPlan: {
      chargeCreditWithCardResponse,
      chargeCreditWithCardStatus,
      chargeCreditWithCryptoStatus,
      chargeCreditWithCryptoResponse,
    },
  } = useSelector((state) => state);
  const [value, setValue] = useState(5);
  const [paymentMethod, setPaymentMethod] = useState("cart");

  const dispatch = useDispatch();

  const confirmBtnText =
    (paymentMethod === "cart" &&
      chargeCreditWithCardResponse?.can_proceed === "True") ||
    (paymentMethod === "crypto" &&
      chargeCreditWithCryptoResponse?.can_proceed === "True")
      ? "Change"
      : "Confirm";

  const handleChange = (value) => {
    if (value >= 5) {
      setValue(value);
    }
  };

  const confirmCharge = () => {
    if (
      (paymentMethod === "cart" && chargeCreditWithCardStatus === "loading") ||
      (paymentMethod === "crypto" && chargeCreditWithCryptoStatus === "loading")
    )
      return;

    if (paymentMethod === "crypto") {
      if (chargeCreditWithCryptoStatus !== "loading") {
        dispatch(
          chargeCreditWithCrypto(
            userData?.["custom:custom_username"],
            userData?.email,
            `${value}`,
          ),
        );
      }
    }

    if (paymentMethod === "cart") {
      if (chargeCreditWithCardStatus !== "loading") {
        // setPaymentMethod("cart");
        dispatch(
          chargeCreditWithCart(
            userData?.["custom:custom_username"],
            userData?.email,
            `${value}`,
          ),
        );
      }
    }
  };

  const useGiftCode = () => {
    if (
      chargeCreditWithCardStatus === "loading" ||
      chargeCreditWithCryptoStatus === "loading"
    )
      return;
    setPaymentMethod("redeem");
  };

  return (
    <>
      <h4 className={styles.title}>Increase Account Credit</h4>
      <p style={{ marginBottom: "1rem" }}>
        You can increase your account credit using Credit Card, Crypto or Promo
        Gift Codes.
      </p>
      <div className={`${styles.payOptions} ${btnsClassName || ""}`}>
        <Button
          className={`${
            paymentMethod === "cart"
              ? selectedOptionClassName || styles.selected
              : ""
          } ${styles.btn} ${optionBtn || ""}`}
          onClick={() => setPaymentMethod("cart")}
          loading={chargeCreditWithCardStatus === "loading"}
        >
          {chargeCreditWithCardStatus !== "loading" && <FaRegCreditCard />}
          Credit Card
        </Button>
        <Button
          className={`${
            paymentMethod === "crypto"
              ? selectedOptionClassName || styles.selected
              : ""
          } ${styles.btn} ${optionBtn || ""}`}
          onClick={() => setPaymentMethod("crypto")}
        >
          <BsCurrencyBitcoin size={14} /> Crypto
        </Button>
        <Button
          className={`${
            paymentMethod === "redeem"
              ? selectedOptionClassName || styles.selected
              : ""
          } ${styles.btn} ${optionBtn || ""}`}
          onClick={useGiftCode}
        >
          <MdRedeem size={14} /> Gift Code
        </Button>
      </div>

      {(paymentMethod === "cart" || paymentMethod === "crypto") && (
        <>
          <ChargeCreditInput
            customClassName={`${styles.chargeInput} ${inputClassName || ""}`}
            value={value}
            handleChange={handleChange}
          />
          <div className={styles.confirmBtnContainer}>
            <span className={styles.confirmBtn} onClick={confirmCharge}>
              {confirmBtnText}
            </span>
          </div>
        </>
      )}

      {paymentMethod === "cart" && (
        <div className={styles.paymentBox}>
          {chargeCreditWithCardStatus === "loading" && (
            <Spin
              size={"small"}
              tip="Please wait"
              style={{ display: "flex", alignItems: "baseline", gap: ".2rem" }}
            />
          )}
          {chargeCreditWithCardResponse?.can_proceed === "True" && (
            <div className={styles.checkoutBox}>
              <p>
                Click on the ‘Proceed to Checkout’ button to complete your
                payment.
              </p>
              <a
                className={`${styles.paymentBtn} ${paymentBtnClassName || ""}`}
                href={chargeCreditWithCardResponse?.checkout_session_url}
              >
                Proceed to Checkout
              </a>
            </div>
          )}
        </div>
      )}

      {paymentMethod === "crypto" && (
        <div className={styles.paymentBox}>
          {chargeCreditWithCryptoStatus === "loading" && (
            <Spin
              size={"small"}
              tip="Please wait"
              style={{ display: "flex", alignItems: "baseline", gap: ".2rem" }}
            />
          )}
          {chargeCreditWithCryptoResponse?.can_proceed === "True" && (
            <div className={styles.checkoutBox}>
              <p>
                Click on the ‘Proceed to Checkout’ button to complete your
                payment.
              </p>
              <a
                className={`${styles.paymentBtn} ${paymentBtnClassName || ""}`}
                href={
                  chargeCreditWithCryptoResponse?.checkout_session_url?.data
                    ?.invoice_url
                }
              >
                Proceed to Checkout
              </a>
            </div>
          )}
        </div>
      )}

      {paymentMethod === "redeem" && (
        <RedeemGift
          customClassName={`${styles.redeemInput} ${redeemClassName || ""}`}
        />
      )}

      {/*<div className={styles.redeemGiftBox}>*/}
      {/*  <h3>Redeem Gift Code</h3>*/}
      {/*  */}
      {/*</div>*/}
    </>
  );
}

export default MiniCreditCharge;
