import { useNavigate, useParams } from "react-router";

import styles from "../styles/shared/ConnectExchange.module.css";
import { ImArrowLeft2 } from "react-icons/im";
import { useEffect, useState } from "react";
import InputComponent from "./connect-exchange/InputComponent";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, message, Spin } from "antd";
import {
  getExchangesInfo,
  getTrustedIps,
  integrateWithExchange,
} from "../redux/exchange/actions";
import loader from "../asset/Icons/loader.gif";
import VideoPlayer from "./video-player/VideoPlayer";
import { FaRegCopy } from "react-icons/fa";
import LinkToVirtualModal from "./connect-exchange/LinkToVirtualModal";
import usePageTitle from "../hooks/usePageTitle";

function ConnectExchange() {
  const [apiKey, setApiKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [passphrase, setPassphrase] = useState("");
  const [isRestricted, setIsRestricted] = useState(false);
  const [isProcessed, setIsProcessed] = useState(false);
  const [showLinkToVirtual, setShowLinkToVirtual] = useState(false);

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    main: { userData },
    exchangeState: {
      integrateExchangeLoading,
      integrateExchangeMessage,
      integrateExchangeError,
      previousPath,
      exchangeForConnection,
      exchangesConnectionInfo,
      exchangesLoading,
      trustedIps,
      getTrustedIpsStatus,
    },
  } = useSelector((state) => state);

  const connectionInfo = exchangesConnectionInfo?.find(
    (item) => item.id === exchangeForConnection,
  );

  const forcedInfo = connectionInfo?.mandatory;

  const isTestExchange = params?.id === "Test Exchange";

  usePageTitle(`Connect to ${params?.id || "Exchange"}`);

  const isConnected =
    isProcessed &&
    !integrateExchangeLoading &&
    integrateExchangeMessage?.STATUS_CODE === 200 &&
    !integrateExchangeError;

  const goBackHandler = () => {
    // navigate(localStorage.getItem("previous-path"));
    navigate(previousPath);
  };

  const copyToClipboardHandler = () => {
    setIsRestricted(true);
    navigator.clipboard.writeText(trustedIps?.[0]);
    message.success("Copied to clipboard.");
  };

  const submitHandler = async () => {
    if (integrateExchangeLoading) return;
    if (
      (forcedInfo?.includes("key") && !apiKey) ||
      (forcedInfo?.includes("secret") && !secretKey) ||
      (forcedInfo?.includes("passphrase") && !passphrase)
    )
      return message.warn("Please complete the necessary fields.");

    const ip =
      exchangeForConnection === "cryptocrispy"
        ? ""
        : isRestricted
          ? trustedIps?.[0]
          : "";

    await dispatch(
      integrateWithExchange(
        userData["custom:custom_username"],
        userData?.email,
        apiKey,
        secretKey,
        exchangeForConnection,
        passphrase,
        // isRestricted,
        ip,
        params?.id,
      ),
    ).then(() => {
      setIsProcessed(true);
    });
  };

  const handleCloseModal = () => {
    setShowLinkToVirtual(false);
    navigate(previousPath || "/integration");
  };

  useEffect(() => {
    if (isConnected) {
      setShowLinkToVirtual(true);
    }
  }, [isConnected]);

  useEffect(() => {
    if (params?.id === "CryptoCrispy") {
      setApiKey("cryptocrispy");
      setSecretKey("cryptocrispy");
    }
  }, [params]);

  useEffect(() => {
    if (!userData["cognito:username"]) {
      navigate(previousPath || "/integration");
    }
  }, [previousPath, userData, navigate]);

  useEffect(() => {
    if (
      !exchangesConnectionInfo.length &&
      Object.keys(userData).length &&
      !exchangesLoading
    ) {
      dispatch(getExchangesInfo());
    }
  }, [exchangesConnectionInfo, userData, exchangesLoading]);

  useEffect(() => {
    if (
      userData &&
      Object.keys(userData)?.length &&
      !trustedIps.length &&
      getTrustedIpsStatus !== "error" &&
      getTrustedIpsStatus !== "loading"
    ) {
      dispatch(getTrustedIps());
    }
  }, [dispatch, trustedIps, userData, getTrustedIpsStatus]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={styles.logo}>Crypto Crispy</span>
        <span className={styles.backBtn} onClick={goBackHandler}>
          <ImArrowLeft2 />
        </span>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <h3>Connect to {params.id}</h3>
          {!isTestExchange ? (
            <p>Using real funds.</p>
          ) : (
            <p>Using virtual funds.</p>
          )}
        </div>
        <div className={styles.body}>
          {exchangesLoading && (
            <div className={styles.guideLoader}>
              <Spin tip={"Loading..."} />
            </div>
          )}
          {!exchangesLoading && (
            <>
              <div className={styles.form}>
                {isTestExchange && (
                  <p>
                    <strong style={{ marginRight: ".3rem" }}>NOTE:</strong>
                    You can use any API key and secret key.
                  </p>
                )}
                <InputComponent
                  value={apiKey}
                  label="API Key"
                  setValue={setApiKey}
                />
                {connectionInfo?.mandatory?.includes("secret") ? (
                  <InputComponent
                    value={secretKey}
                    label="Secret Key"
                    setValue={setSecretKey}
                  />
                ) : (
                  ""
                )}

                {connectionInfo?.mandatory?.includes("passphrase") ? (
                  <InputComponent
                    value={passphrase}
                    label="API Passphrase"
                    setValue={setPassphrase}
                  />
                ) : (
                  ""
                )}

                {connectionInfo?.optional && (
                  <div className={styles.optional}>
                    <h4>Optional</h4>
                  </div>
                )}
                {connectionInfo?.optional?.includes("secret") ? (
                  <InputComponent
                    value={secretKey}
                    label="Secret Key"
                    setValue={setSecretKey}
                  />
                ) : (
                  ""
                )}

                {connectionInfo?.optional?.includes("passphrase") ? (
                  <InputComponent
                    value={passphrase}
                    label="API Passphrase"
                    setValue={setPassphrase}
                  />
                ) : (
                  ""
                )}

                {!isTestExchange && (
                  <div className={styles.restrictionBox}>
                    <h4>IP Whitelist Values</h4>
                    <div className={styles.restrictionOption}>
                      <Checkbox
                        className={`${styles.checkbox} ${
                          isRestricted ? styles.hideBorder : ""
                        }`}
                        value={isRestricted}
                        checked={isRestricted}
                        onChange={() =>
                          setIsRestricted((prevState) => !prevState)
                        }
                      />
                      <p>
                        Copy the IP value below and add it to your exchange.
                        This value is required so that the connection continues
                        uninterrupted.
                      </p>
                    </div>
                    <div
                      className={styles.ipBox}
                      onClick={copyToClipboardHandler}
                    >
                      <span>{trustedIps?.[0]}</span>
                      <span className={styles.copyOption}>
                        <FaRegCopy style={{ marginRight: ".3rem" }} />
                        Copy to clipboard
                      </span>
                    </div>
                  </div>
                )}

                <div
                  className={styles.submitBtn}
                  onClick={submitHandler}
                  style={{
                    cursor: `${integrateExchangeLoading ? "wait" : "pointer"}`,
                  }}
                >
                  {integrateExchangeLoading ? (
                    <img
                      src={loader}
                      alt={"loading"}
                      style={{ height: "20px" }}
                    />
                  ) : (
                    `Connect to ${params.id}`
                  )}
                </div>
              </div>
              {!isTestExchange && (
                <div className={styles.guide}>
                  {connectionInfo && (
                    <div className={styles.guideContent}>
                      <VideoPlayer videoLink={connectionInfo.video_url} />
                      <ul className={styles.stepsList}>
                        {params.id !== "CryptoCrispy" && (
                          <li className={styles.stepsTitle}>
                            <h4>Create an API key on {params.id}</h4>
                          </li>
                        )}
                        {connectionInfo.steps.split(";;").map((step, i) => (
                          <li className={styles.stepsItem} key={step}>
                            {i + 1}. {step}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <LinkToVirtualModal
        show={showLinkToVirtual}
        setShow={setShowLinkToVirtual}
        onClose={handleCloseModal}
      />
    </div>
  );
}

export default ConnectExchange;
