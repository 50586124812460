import { useParams } from "react-router";
import DashboardLayout from "../../components/layout/DashboardLayout";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaCreditCard, FaRegCreditCard, FaUsers } from "react-icons/fa";
import { Tabs } from "antd";
import styles from "../../styles/pages/pricingPlan/PricingBillingIndex.module.css";
import Header from "./Header";
import Subscription from "../../components/pricing-plan/Subscription";
import Rewards from "./Rewards";
import ChargeCredit from "../../components/pricing-plan/ChargeCredit";
import { getSubscriptions } from "../../redux/pricing-plan/action";
import { useDispatch, useSelector } from "react-redux";
import usePageTitle from "../../hooks/usePageTitle";
import PricingDiscountButton from "./PricingDiscountButton";

function PricingBillingIndex() {
  const {
    main: { userData },
    pricingPlan: { subscriptions, getSubscriptionLoading, subscriptionError },
  } = useSelector((state) => state);

  const { section } = useParams();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("credit");

  usePageTitle("Pricing & Billing");

  const tabItems = [
    {
      key: "credit",
      label: (
        <Link
          to={"/pricing-billing/credit"}
          className={`${styles.tabLabel} ${
            activeTab === "credit" ? styles.activeTab : ""
          }`}
        >
          <FaCreditCard />
          <span>Account Credit</span>
        </Link>
      ),
      children: <ChargeCredit />,
    },
    {
      key: "subscription",
      label: (
        <Link
          to={"/pricing-billing/subscription"}
          className={`${styles.tabLabel} ${
            activeTab === "subscription" ? styles.activeTab : ""
          }`}
        >
          <FaRegCreditCard /> <span>Subscription</span>
        </Link>
      ),
      children: <Subscription />,
    },
    {
      key: "rewards",
      label: (
        <Link
          to={"/pricing-billing/rewards"}
          className={`${styles.tabLabel} ${
            activeTab === "rewards" ? styles.activeTab : ""
          }`}
        >
          <FaUsers /> <span>Referrals</span>
        </Link>
      ),
      children: <Rewards />,
    },
  ];

  useEffect(() => {
    if (!section) {
      setActiveTab("credit");
    } else {
      setActiveTab(section);
    }
  }, [section]);

  useEffect(() => {
    if (
      userData?.email &&
      !Object.keys(subscriptions).length &&
      !getSubscriptionLoading &&
      !subscriptionError
    ) {
      dispatch(
        getSubscriptions(
          userData["cognito:username"],
          userData?.["custom:custom_username"],
        ),
      );
    }
  }, [userData, subscriptions, getSubscriptionLoading, subscriptionError]);

  return (
    <DashboardLayout>
      <Header />

      {/*<PricingDiscountButton />*/}

      <Tabs
        activeKey={activeTab}
        items={tabItems}
        onChange={(key) => setActiveTab(key)}
        type="card"
      />
    </DashboardLayout>
  );
}

export default PricingBillingIndex;
