import { useSelector } from "react-redux";
import { Spin } from "antd";
import { BsCashCoin } from "react-icons/bs";
import React from "react";
import styles from "../../../styles/Components/mini-app/mini-bots/TestExchangeInfo.module.css";

function MiniAppExchangeInfo() {
  const {
    exchangeState: { portfolioData, portfolioLoading },
  } = useSelector((state) => state);

  const balance = +portfolioData?.find((item) => item?.assetName === "USDT")
    ?.availableBalance;

  return (
    <ul className={styles.container}>
      {portfolioLoading ? (
        <li>
          <span style={{ color: "#0C359E", fontSize: "12px" }}>
            Calculating available balance
          </span>
          <Spin size="small" />
        </li>
      ) : (
        <>
          <li>
            <img
              className={styles.logo}
              src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/cryptocrispy.svg`}
              alt="Test exchange"
            />
            <span>Test exchange</span>
          </li>
          <li className={styles.balanceInfo}>
            <span>
              <BsCashCoin color="#0C359E" />
              Available Balance:
            </span>
            <p>${balance?.toFixed(2)}</p>
          </li>
        </>
      )}
    </ul>
  );
}

export default MiniAppExchangeInfo;
