import React, { useEffect, useState } from "react";
import styles from "../../../styles/shared/trade-modal/Step.module.css";
import { Button, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getTradePortfolio,
  setAsset,
  setAssetAmount,
  setAssetAmountUsd,
  setExchange,
  setSecondTradeType,
  setStep,
  setTradeAmount,
  setTradeAmountUsd,
  setTradeType,
  setTriggerPrice,
  setUsdtAmount,
} from "../../../redux/trade/actions";
import {
  getAssetList,
  getIntegratedExchanges,
} from "../../../redux/exchange/actions";
import Loader from "./Loader";
import { Link } from "react-router-dom";
import { AiFillCaretRight } from "react-icons/ai";
import { IoIosWarning } from "react-icons/io";
import { get200Coins } from "../../../redux/trading-assistant/actions";
import { LuRefreshCw } from "react-icons/lu";
import { showWarning } from "../../../utility/tradeHelper";

// A FUNCTION TO CHECK USDT AMOUNT
const checkUsdtAmount = (data) => {
  if (data && data.length) {
    const asset = data.find((item) => item.assetName === "USDT");
    if (asset && parseFloat(asset.availableBalance) > 1) {
      return true; // Item with the specified assetName exists and availableBalanceUSD is greater than 0
    }
    return false; // Item does not exist or availableBalanceUSD is not greater than 0
  }
};

const checkAssetExistence = (data, asset, assetData) => {
  if (data && data.length && !assetData) {
    return data.find((item) => item.assetName === asset);
  }
  if (assetData && assetData.length) {
    return assetData.find((item) => item === asset);
  }
};

function Step1A({ step, setShow, show }) {
  const [isDone, setIsDone] = useState(false);
  const [options, setOptions] = useState([]);
  const [isExchangeChanged, setIsExchangeChanged] = useState(false);
  const [needToRefreshIntegration, setNeedToRefreshIntegration] =
    useState(false);

  const {
    exchangeState: {
      integratedData,
      integratedDataLoading,
      getIntegratedList,
      assetsLoading,
      assetsList,
      integratedDataFetched,
    },
    tradingAssistant: {
      _200Coins: { top_200_coin },
      get200CoinsLoading,
      get200CoinsError,
    },
    tradeState: {
      selectedExchange,
      tradeType,
      selectedAsset,
      tradePortfolio,
      tradePortfolioLoading,
      tradeAmount,
      tradeAmountUsd,
      triggerPrice,
      secondTradeType,
      modalMode,
    },
    main: { userData },
    profile: { profileData },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [isFirstTime, setIsFirstTime] = useState(true);

  const activeBotExchange = profileData?.bot_active_exchange;

  const exchangeList = [...integratedData]
    .filter((item) => item?.status === "Active")
    .sort((a, b) => (b?.id === "cryptocrispy" ? 1 : -1));

  const updateIntegration = async () => {
    if (integratedDataLoading) return;
    await dispatch(
      getIntegratedExchanges(
        userData?.["custom:custom_username"],
        userData?.["cognito:username"],
      ),
    ).then(() => {
      setNeedToRefreshIntegration(false);
    });
  };

  // HANDLE SELECTING TRADER TYPE
  const handleTradeType = (type) => {
    if (selectedExchange) {
      dispatch(setSecondTradeType(""));
      dispatch(setTradeType(type));
      dispatch(setAsset(""));
      if (tradeAmount) {
        dispatch(setTradeAmount(""));
      }

      if (tradeAmountUsd) {
        dispatch(setTradeAmountUsd(""));
      }

      if (triggerPrice) {
        dispatch(setTriggerPrice(""));
      }
    }
  };

  // HANDLE CLOSING MODAL
  const closeHandler = () => {
    setShow();
  };

  useEffect(() => {
    if (!top_200_coin && !get200CoinsLoading && !get200CoinsError) {
      dispatch(
        get200Coins(
          userData?.["cognito:username"],
          userData?.["custom:custom_username"],
        ),
      );
    }
  }, [dispatch, get200CoinsError, get200CoinsLoading, top_200_coin]);

  useEffect(() => {
    if (tradePortfolio && tradePortfolio.length) {
      const asset = tradePortfolio.find((item) => item.assetName === "USDT");
      if (asset && parseFloat(asset.availableBalance) > 0) {
        // SAVE USDT AMOUNT IN THE TRADE STATE
        dispatch(setUsdtAmount(asset.availableBalance));
      }
    }
  }, [tradePortfolio]);

  // GET PORTFOLIO WHEN THE USER CHOOSE THE TRADE TYPE
  useEffect(() => {
    if (selectedExchange) {
      dispatch(
        getAssetList(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          selectedExchange,
        ),
      );
      dispatch(
        getTradePortfolio(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
          selectedExchange,
          "False",
        ),
      );
      // setIsExchangeChanged(false);
    }
  }, [selectedExchange, userData, dispatch]);

  // CREATE ASSET OPTIONS
  useEffect(() => {
    if (
      assetsList.length &&
      !assetsLoading &&
      top_200_coin &&
      tradePortfolio &&
      tradePortfolio.length
    ) {
      // FILTER THE ASSETS AND RETURN COINS
      const coins = assetsList.filter((item) =>
        top_200_coin.includes(item.split("/")[0]),
      );
      if (tradeType === "sell") {
        // SHOW ONLY ASSETS THAT EXIST IN THE PORTFOLIO
        const filteredAssets = coins.filter((asset) => {
          return tradePortfolio.some(
            (item) => item.assetName === asset.split("/")[0],
          );
        });

        const sellOptions = filteredAssets.map((item) => {
          return {
            // value: item.includes("/") ? item.split("/")[0] : item,
            value: item,
            label: (
              <p>
                <img
                  className={styles.symbolIcon}
                  src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${
                    item.includes("/") ? item.split("/")[0] : item
                  }.png`}
                  alt={item.includes("/") ? item.split("/")[0] : item}
                />
                {item}
              </p>
            ),
          };
        });
        setOptions(sellOptions);
      }
      if (tradeType === "buy") {
        const buyOptions = coins.map((item) => {
          return {
            value: item,
            label: (
              <p>
                <img
                  className={styles.symbolIcon}
                  src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/symbol_icons/${
                    item.includes("/") ? item.split("/")[0] : item
                  }.png`}
                  alt={item.includes("/") ? item.split("/")[0] : item}
                />
                {item}
              </p>
            ),
          };
        });
        setOptions(buyOptions);
      }
    }
  }, [tradeType, assetsList, assetsLoading, tradePortfolio, setOptions]);

  // MAKE NEXT BUTTON CLICKABLE IF USER FILL THE NECESSARY FIELDS
  useEffect(() => {
    if (selectedAsset && tradeType && selectedAsset) setIsDone(true);
  }, [selectedExchange, tradeType, selectedAsset]);

  // GET INTEGRATED EXCHANGES IF THEY DON'T FETCH YET
  useEffect(() => {
    if (!getIntegratedList && !integratedDataLoading && userData?.email) {
      dispatch(
        getIntegratedExchanges(
          userData?.["custom:custom_username"],
          userData?.["cognito:username"],
        ),
      );
    }
  }, [getIntegratedList, integratedDataLoading, userData, dispatch]);

  useEffect(() => {
    if (integratedData.length > 0 && !selectedExchange) {
      // Check if the modal is open and integratedData is available
      const firstActiveExchange = integratedData.find(
        (item) => item.status === "Active",
      );
      dispatch(setExchange(firstActiveExchange.id));
      setIsExchangeChanged(true);
    }
  }, [selectedExchange, integratedData, dispatch]);

  return (
    <div className={`${styles.step}`}>
      <h3 className={styles.title}>
        <AiFillCaretRight />
        Select the exchange that you want to order trade with that.
      </h3>
      {integratedDataLoading ? (
        <Loader />
      ) : integratedData.length > 0 ? (
        <div className={styles.steps}>
          <ul className={styles.exchangeList}>
            {exchangeList.map((item, i) => (
              <li
                key={item.id}
                className={`${styles.exchangeItem} ${
                  selectedExchange === item.id ? styles.selected : ""
                } ${item.id === "cryptocrispy" ? styles.virtual : ""}`}
                onClick={() => {
                  if (tradePortfolioLoading) return;
                  setIsExchangeChanged(true);
                  setIsFirstTime(true);
                  dispatch(setAsset(""));
                  dispatch(setExchange(item.id));
                }}
              >
                <img
                  className={styles.exchangeImg}
                  src={`https://cryptocrispypro.s3.ap-southeast-2.amazonaws.com/crypto_exchanges_logo/${item.id}.svg`}
                  alt={item.name}
                />
                <span className={styles.exchangeName}>{item.name}</span>
              </li>
            ))}
          </ul>

          <div
            className={`${styles.tradeTypeBox} ${
              !selectedExchange ? styles.inactive : ""
            }`}
          >
            <h3 className={styles.title}>
              <AiFillCaretRight />
              What type of trade do you want to do?
            </h3>
            <ul className={styles.tradeTypes}>
              <li
                className={`${styles.tradeType} ${
                  tradeType === "buy" ? styles.selected : ""
                }`}
                style={!selectedExchange ? { cursor: "default" } : undefined}
                onClick={() => handleTradeType("buy")}
              >
                Buy
              </li>
              <li
                className={`${styles.tradeType} ${
                  tradeType === "sell" ? styles.selected : ""
                }`}
                style={!selectedExchange ? { cursor: "default" } : undefined}
                onClick={() => handleTradeType("sell")}
              >
                Sell
              </li>
            </ul>
          </div>

          <div
            className={`${styles.assetBox} ${
              !tradeType ? styles.inactive : ""
            }`}
          >
            <h3 className={styles.title}>
              <AiFillCaretRight />
              Select the asset that you want to trade.
            </h3>
            {tradePortfolioLoading || assetsLoading ? (
              <Loader text={"Getting assets"} />
            ) : !checkUsdtAmount(tradePortfolio) && tradeType === "buy" ? (
              <div className={styles.noUsdtBox}>
                <p className={styles.noUsdtMsg}>
                  <IoIosWarning />
                  Sorry you don't have enough USDT in your portfolio!
                </p>
                <span className={styles.closeBtn} onClick={closeHandler}>
                  Close
                </span>
              </div>
            ) : (
              <Select
                showSearch
                className={styles.select}
                placeholder={"Select an asset"}
                optionFilterProp="children"
                value={selectedAsset}
                onChange={(value) => {
                  dispatch(setAsset(value));
                  dispatch(
                    setAssetAmount(
                      tradePortfolio.find(
                        (item) => item.assetName === value.split("/")[0],
                      )?.availableBalance,
                    ),
                  );
                  dispatch(
                    setAssetAmountUsd(
                      tradePortfolio.find(
                        (item) => item.assetName === value.split("/")[0],
                      )?.availableBalanceUSD,
                    ),
                  );
                  if (secondTradeType) {
                    dispatch(setSecondTradeType(""));
                  }
                  if (tradeAmount) {
                    dispatch(setTradeAmount(""));
                  }

                  if (tradeAmountUsd) {
                    dispatch(setTradeAmountUsd(""));
                  }

                  if (triggerPrice) {
                    dispatch(setTriggerPrice(""));
                  }
                }}
                filterOption={(input, option) =>
                  (option?.value ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={
                  assetsLoading ? [{ label: "Loading...", value: "" }] : options
                }
              />
            )}
          </div>

          <span
            className={`${styles.nextBtn} ${!isDone ? styles.inactive : ""}`}
            onClick={() => {
              if (isDone) {
                if (activeBotExchange === selectedExchange && isFirstTime) {
                  showWarning();
                  setIsFirstTime(false);
                } else {
                  dispatch(setStep("2"));
                }
              }
            }}
          >
            Next
          </span>
        </div>
      ) : (
        <p className={styles.needToIntegrate}>
          You didn't integrate with any exchange.{" "}
          <Link
            className={styles.integrationLink}
            onClick={() => setNeedToRefreshIntegration(true)}
            to={"/integration"}
            target={"_blank"}
          >
            Integrate now!
          </Link>
          {needToRefreshIntegration && (
            <>
              <Button
                type={"primary"}
                onClick={updateIntegration}
                loading={integratedDataLoading}
              >
                Refresh <LuRefreshCw />
              </Button>
            </>
          )}
        </p>
      )}
    </div>
  );
}

export default Step1A;
