import ServiceActivationModal from "../../shared/service-activation/ServiceActivationModal";
import React from "react";

function AiAssistantServiceActivationModal({
  showActivationModal,
  setShowActivationModal,
}) {
  if (!showActivationModal) return null;

  return (
    <ServiceActivationModal
      service={"myais"}
      show={showActivationModal}
      setShow={setShowActivationModal}
    />
  );
}

export default AiAssistantServiceActivationModal;
