import React, { createContext, useContext } from "react";
import { Modal, Spin } from "antd";
import { useSelector } from "react-redux";
import CreateStrategy from "./CreateStrategy";
import styles from "../../../styles/Components/new-insight/create-strategy/CreateStrategyModal.module.css";
import StrategyContextProvider from "./useCreateStrategyContext";

export const ShowCreateStrategyContext = createContext();

function CreateStrategyModal({ show, closeHandler, type }) {
  const {
    newInsightState: {
      categoriesFeatureTree,
      getInitialDataLoading,
      createStrategyStatus,
    },
  } = useSelector((state) => state);

  function closeModal() {
    if (createStrategyStatus === "loading") return;
    closeHandler();
  }

  return (
    <ShowCreateStrategyContext.Provider value={closeModal}>
      <Modal
        open={show}
        onCancel={closeModal}
        maskClosable={false}
        centered
        footer={null}
        width={800}
        className={styles.modal}
      >
        {getInitialDataLoading &&
        Object.keys(categoriesFeatureTree).length === 0 ? (
          <div className={styles.loader}>
            <Spin tip={"Fetching features list..."} />
          </div>
        ) : (
          <StrategyContextProvider>
            <CreateStrategy action={type} />
          </StrategyContextProvider>
        )}
      </Modal>
    </ShowCreateStrategyContext.Provider>
  );
}

const useShowCreateStrategyContext = () => {
  const context = useContext(ShowCreateStrategyContext);

  if (context === undefined)
    throw new Error("Modal context was used outside of the context provider");

  return context;
};

export { useShowCreateStrategyContext };

export default CreateStrategyModal;
