import DashboardStatusBox from "./DashboardStatusBox";
import { FaRegCreditCard } from "react-icons/fa";
import { formatPrice } from "../../../utility/helper";
import { useSelector } from "react-redux";
import { getRelativeTime } from "../../../utility/formatter-helper";
import { Spin } from "antd";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function AccountCreditInfo({ loading, credit }) {
  const {
    profile: { profileData, loading: profileLoading },
  } = useSelector((state) => state);

  const navigate = useNavigate();

  return (
    <DashboardStatusBox
      title={"Account"}
      icon={<FaRegCreditCard />}
      path={"/pricing-billing/credit"}
      loading={loading}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: ".5rem",
        }}
      >
        <span>Credit:</span>
        <span>{`$${formatPrice(credit / 100, 2)}`}</span>
        <MdOutlineAddCircleOutline
          style={{ cursor: "pointer", color: "#0C4CFC" }}
          size={18}
          onClick={() => navigate("/pricing-billing/credit")}
        />
      </div>
      {!profileData?.createdDate && (
        <Spin size={"small"} style={{ alignSelf: "center" }} />
      )}
      {profileData?.createdDate && (
        <span>Joined {getRelativeTime(profileData?.createdDate)}</span>
      )}
    </DashboardStatusBox>
  );
}

export default AccountCreditInfo;
