import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  saveTelegramAuthData,
  setToken,
  setUserData,
} from "../redux/main/action/main";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { formatUserData } from "../utility/helper";
import { getProfileData } from "../redux/profile/actions";
import { Spin } from "antd";
import styles from "../styles/pages/TelegramAuthentication.module.css";
import logo from "../asset/Images/cryptocrispyLogo.png";
import { IoMdCloseCircle } from "react-icons/io";
import MiniAppLoginPage from "../components/mini-app/auth/MiniAppLoginPage";
import { routeConvertor } from "../utility/formatter-helper";

// "/mini-dashboard"
// "/mini-ai-signals"
// "/mini-bots"
// "/mini-referrals"
// "/mini-pricing-plan"

// integration
// dashboard
// Insight
// bot-automation
// pricing-billing
// developer-api
// ai-assistant
// trade-simulator
// alert-system
// faq
// profile/info

function TelegramAuthentication() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);

  const [status, setStatus] = useState("loading");
  const [error, setError] = useState("");
  const [showAuthPage, setShowAuthPage] = useState(false);

  // Get the value of the 'telegram_auth' parameter
  const telegramAuth = params.get("tgWebAppStartParam");

  // Get the value of the 'user' parameter
  const telegram_id = telegramAuth?.slice(0, 20);
  const user = telegramAuth?.slice(20, 25);
  const email = telegramAuth?.slice(25, 30);
  const next = telegramAuth?.slice(-5);

  const checkAuthentication = async () => {
    if (error) setError("");
    setStatus("loading");

    await axios
      .post(
        "https://0znr1xesf9.execute-api.ap-southeast-2.amazonaws.com/dev/api",
        {
          operation_mode: "login",
          telegram_id,
          email,
          user,
        },
      )
      .then((res) => {
        const {
          accessToken: { jwtToken, payload },
        } = res?.data;
        setStatus("done");
        // console.log(res?.data);
        dispatch(setToken(jwtToken));
        dispatch(setUserData(formatUserData(res?.data?.accessToken)));
        axios.defaults.headers.common["Authorization"] = `${jwtToken}`;
        // dispatch(
        //   getProfileData(payload?.email, payload["custom:custom_username"]),
        // );
        localStorage.setItem("telegramData", telegramAuth);
        if (next) {
          if (next !== "repor") {
            navigate(`/${routeConvertor[next]}`);
          } else {
            window.location.href = "https://cryptocrispy.com.au/reports.html";
          }
        }
      })
      .catch((err) => {
        setShowAuthPage(true);
        // if (err?.response?.status === 421) {
        //   setShowAuthPage(true);
        // } else {
        //   setStatus("error");
        //   setError(err?.response?.data?.message);
        // }
      });
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img src={logo} alt={"logo"} />
      </div>
      {!showAuthPage && (
        <>
          {status === "loading" && (
            <div className={styles.loader}>
              <Spin />
              {/*<h2>Signing to CryptoCrispy, please wait</h2>*/}
            </div>
          )}
          {status === "error" && (
            <div className={styles.errorContainer}>
              <IoMdCloseCircle size={30} color={"#e84118"} />
              <h3>{error}</h3>
            </div>
          )}
        </>
      )}

      {showAuthPage && <MiniAppLoginPage />}
    </div>
  );
}

export default TelegramAuthentication;
