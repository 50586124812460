import styles from "../styles/ui/SortBy.module.css";
import { useSearchParams } from "react-router-dom";
import ActionButtonsContainer from "./ActionButtonsContainer";

function SortBy({ options, className = "", style = {} }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const sortValue = searchParams.get("sortBy") || options?.at(0)?.value;
  const clickHandler = (val) => {
    searchParams.set("sortBy", val);
    setSearchParams(searchParams);
  };

  return (
    <ActionButtonsContainer className={className} style={style}>
      {options.map((op) => (
        <li
          className={`${styles.button} ${
            sortValue === op.value ? styles.active : ""
          }`}
          key={op.value}
          onClick={() => clickHandler(op.value)}
        >
          {op?.icon && <span>{op.icon}</span>}
          {op?.label && (
            <span className={`${op?.icon ? styles.label : ""}`}>
              {op.label}
            </span>
          )}
        </li>
      ))}
    </ActionButtonsContainer>
  );
}

export default SortBy;
