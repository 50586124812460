import React, { useEffect, useState } from "react";
import IntegratedExchangeList from "./portfolio-section/IntegratedExchangeList";
import TradeModal from "../../shared/trade-modal/TradeModal";
import styles from "../../styles/Components/integration/Portfolio.module.css";
import ExchangesAndPaperTradingTab from "../../shared/exchanges-papertrading-tabs/ExchangesAndPaperTradingTab";
import ExchangeNewPortfolioTable from "./portfolio-section/ExchangeNewPortfolioTable";
import { Tabs } from "antd";
import { ImTable } from "react-icons/im";
import TabPane from "antd/lib/tabs/TabPane";
import { TbChartPieFilled } from "react-icons/tb";
import ExchangePortfolioTableActionButtons from "./portfolio-section/ExchangePortfolioTableActionButtons";
import ExchangePortfolioCharts from "./portfolio-section/ExchangePortfolioCharts";
import ExchangeBalanceInfo from "./portfolio-section/ExchangeBalanceInfo";
import useTrackSpendTime from "../../hooks/useTrackSpendTime";
import { useSelector } from "react-redux";

function Portfolio() {
  const {
    exchangeState: { portfolioData },
  } = useSelector((state) => state);

  const [showTradeModal, setShowTradeModal] = useState(false);

  useTrackSpendTime("exchange-portfolio", portfolioData?.length > 0);

  return (
    <div id="exchange-portfolio">
      <h3 className={styles.title}>
        <strong>Portfolio</strong>
      </h3>

      <ExchangesAndPaperTradingTab
        activeTab={"exchange"}
        pageType={"portfolio"}
      />

      <IntegratedExchangeList />

      <ExchangePortfolioTableActionButtons />
      <Tabs type={"card"} className={`${styles.tabs} custom-tabs`} animated>
        <TabPane
          tab={
            <span>
              <ImTable size={18} /> Portfolio Table
            </span>
          }
          key={"table"}
        >
          <ExchangeNewPortfolioTable />
        </TabPane>
        <TabPane
          tab={
            <span>
              <TbChartPieFilled size={18} />
              Portfolio Charts
            </span>
          }
          key={"chart"}
        >
          <ExchangeBalanceInfo />
          <ExchangePortfolioCharts />
        </TabPane>
      </Tabs>
      {/*<IntegratedExchangeList page={"portfolio"} />*/}

      {/*<ExchangePortfolioTable />*/}
      <TradeModal show={showTradeModal} setShow={setShowTradeModal} />
    </div>
  );
}

export default Portfolio;
