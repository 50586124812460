import { Badge, Button, message, Modal } from "antd";
import { useEffect, useState } from "react";
import styles from "../../styles/shared/link-with-exchange/UnlinkFromExchange.module.css";
import { BiUnlink } from "react-icons/bi";
import { IoMdArrowDropright } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { unlinkFromExchange } from "../../redux/exchange/actions";

function UnlinkFromExchange({
  style,
  className,
  isLink,
  btnText = "Unlink from Exchange",
  portfolio,
}) {
  const {
    profile: { profileData },
    exchangeState: { unlinkFromExchangeLoading },
    main: { userData },
  } = useSelector((state) => state);

  const [showModal, setShowModal] = useState(false);
  const [selectedPortfolio, setSelectedPortfolio] = useState(null);

  const dispatch = useDispatch();

  const isFirstPortfolioConnected =
    profileData?.portfolio1_linked_exchange &&
    profileData?.portfolio1_linked_exchange !== "None";
  const isSecondPortfolioConnected =
    profileData?.portfolio2_linked_exchange &&
    profileData?.portfolio2_linked_exchange !== "None";

  const isBotDoingTradesOnPortfolio =
    profileData?.is_bot_forwarding_trades === "True";
  const isBotDoingTradesOnLinkedExchange =
    profileData?.bot_active_exchange ===
    profileData?.portfolio1_linked_exchange;

  const showWarningForUnlink = (portfolio) => {
    Modal.confirm({
      title: "Warning",
      centered: true,
      content:
        "A bot is forwarding trades to this portfolio. We recommend not unlinking this portfolio from the exchange.",
      okText: "No problem",
      okButtonProps: {
        style: {
          backgroundColor: "#2b5fec",
          borderColor: "#2b5fec",
        },
      },
      onOk: async () => {
        await dispatch(
          unlinkFromExchange(
            userData?.["cognito:username"],
            userData?.["custom:custom_username"],
            portfolio,
          ),
        ).then(() => Modal.destroyAll());
      },
      onCancel: () => {
        modalCloseHandler();
      },
    });
  };

  const btnClickHandler = () => {
    if (portfolio) {
      if (
        portfolio === "primary" &&
        isBotDoingTradesOnPortfolio &&
        isBotDoingTradesOnLinkedExchange
      ) {
        showWarningForUnlink(portfolio);
        return;
      }
      dispatch(
        unlinkFromExchange(
          userData?.["cognito:username"],
          userData?.["custom:custom_username"],
          portfolio,
        ),
      );
      return;
    }
    setShowModal(true);
  };

  const modalCloseHandler = () => {
    if (unlinkFromExchangeLoading) return;

    setShowModal(false);
    setSelectedPortfolio(null);
  };

  const handleSelectPortfolio = (type) => {
    if (
      (type === "secondary" && !isSecondPortfolioConnected) ||
      (type === "primary" && !isFirstPortfolioConnected)
    ) {
      return;
    }

    setSelectedPortfolio(type);
  };

  const submitHandler = async () => {
    if (unlinkFromExchangeLoading) return;

    if (!selectedPortfolio)
      return message.info("Please select a virtual portfolio.");

    if (isBotDoingTradesOnPortfolio && isBotDoingTradesOnLinkedExchange) {
      showWarningForUnlink(selectedPortfolio);
      return;
    }

    await dispatch(
      unlinkFromExchange(
        userData?.["cognito:username"],
        userData?.["custom:custom_username"],
        selectedPortfolio,
      ),
    ).then(() => {
      modalCloseHandler();
    });
  };

  useEffect(() => {
    if (Object.keys(profileData).length > 0 && showModal) {
      if (isFirstPortfolioConnected) {
        setSelectedPortfolio("primary");
        return;
      }

      if (isSecondPortfolioConnected) {
        setSelectedPortfolio("secondary");
      }
    }
  }, [profileData, showModal]);

  return (
    <div style={style || {}} className={className || styles.container}>
      <span className={!className ? styles.btn : ""} onClick={btnClickHandler}>
        <BiUnlink />
        {btnText}
      </span>
      <Modal
        open={showModal}
        footer={null}
        centered
        width={400}
        onCancel={modalCloseHandler}
        closable={!unlinkFromExchangeLoading}
        bodyStyle={{ padding: "1rem 24px" }}
      >
        <h4 style={{ marginBottom: "1.2rem" }}>
          <IoMdArrowDropright />
          Select the portfolio that you want to unlink:
        </h4>
        <ul className={styles.portfolioList}>
          <li
            className={`${styles.portfolioItem} ${
              !isFirstPortfolioConnected ? styles.disable : ""
            } ${
              selectedPortfolio === "primary" ? styles.selectedPortfolio : ""
            }`}
            onClick={() => handleSelectPortfolio("primary")}
          >
            {isFirstPortfolioConnected ? (
              <Badge
                count={profileData?.portfolio1_linked_exchange}
                size={"default"}
                status={"default"}
                className={styles.badge}
              >
                First
              </Badge>
            ) : (
              <span>First</span>
            )}
          </li>

          <li
            className={`${styles.portfolioItem} ${
              !isSecondPortfolioConnected ? styles.disable : ""
            } ${
              selectedPortfolio === "secondary" ? styles.selectedPortfolio : ""
            }`}
            onClick={() => handleSelectPortfolio("secondary")}
          >
            {isSecondPortfolioConnected ? (
              <Badge
                count={profileData?.portfolio2_linked_exchange}
                size={"small"}
                status={"default"}
                className={styles.badge}
              >
                Second
              </Badge>
            ) : (
              <span>Second</span>
            )}
          </li>
        </ul>

        <Button
          onClick={submitHandler}
          className={`${styles.submitBtn} ${
            !selectedPortfolio ? styles.disable : ""
          }`}
          loading={unlinkFromExchangeLoading}
        >
          Submit
        </Button>
      </Modal>
    </div>
  );
}

export default UnlinkFromExchange;
