import { useSelector } from "react-redux";
import moment from "moment";

function UseUserTier(service = "") {
  const {
    profile: { profileData },
  } = useSelector((state) => state);

  const currentDate = moment();
  const verificationDate = profileData?.last_location_verification_date;
  // || "2020-10-10 20:00:00";
  const lastTry = verificationDate ? moment(verificationDate) : undefined;
  const passedTime = currentDate.diff(lastTry, "months");

  const isVerified = profileData?.tier;
  const canTry = !isVerified || (isVerified && passedTime >= 3);
  const tier = profileData?.tier;
  const tierNum = profileData?.tier?.split(" ")?.[1];
  const suffix = "t" + tierNum;
  const country = profileData?.user_country;

  const serviceType =
    isVerified && tier !== "Tier 1" ? service + "_" + suffix : service;

  // For test
  // const serviceType = service + "_t3";

  return {
    isVerified,
    tier,
    tierNum,
    suffix,
    serviceType,
    country,
    canTry,
    passedTime,
  };
}

export default UseUserTier;
