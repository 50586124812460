import { useEffect, useRef } from "react";

function useTrackSpendTime(route, condition) {
  const timeRef = useRef(0); // Mutable time reference
  const intervalRef = useRef(null); // Reference to store the interval ID
  const hasKeySetRef = useRef(false); // Tracks if the key is already set

  const localStorageKey = `visit-${route}`;

  useEffect(() => {
    if (condition) {
      // Check if the key is already in localStorage
      if (localStorage.getItem(localStorageKey)) {
        hasKeySetRef.current = true;
      }

      // Start the timer
      intervalRef.current = setInterval(() => {
        timeRef.current += 1; // Increment the time in ref

        // Check if the timer exceeds 30 and the key hasn't been set
        if (timeRef.current > 30 && !hasKeySetRef.current) {
          localStorage.setItem(
            localStorageKey,
            JSON.stringify(timeRef.current),
          );
          hasKeySetRef.current = true; // Mark key as set
        }
      }, 1000);
    }

    // Cleanup on unmount
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [localStorageKey, condition]);

  return timeRef.current; // Return the ref value for potential use
}

export default useTrackSpendTime;
