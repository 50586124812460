import styles from "../../styles/shared/service-activation/DisplayActivationWithCreditResponse.module.css";
import { FaCheckCircle } from "react-icons/fa";
import { RiAlertFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import Loader from "../../components/mini-app/shared/Loader";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import React from "react";

function DisplayActivationWithPaymentMethodsResponse({
  method,
  changeContent,
  setStep,
}) {
  const {
    pricingPlan: {
      activateServiceWithCryptoResponse,
      activateServiceWithCryptoStatus,
      activateServiceWithCardStatus,
      activateServiceWithCardResponse,
    },
  } = useSelector((state) => state);

  const goBack = () => {
    if (setStep) {
      setStep(2);
    }

    changeContent("methods");
  };

  return (
    <div className={styles.activationBox}>
      {activateServiceWithCryptoStatus === "loading" ||
      activateServiceWithCardStatus === "loading" ? (
        <Loader height={"150px"} />
      ) : (
        <>
          {changeContent && (
            <div className={styles.backBtn} onClick={goBack}>
              <IoArrowBackCircleOutline />
            </div>
          )}
          {method === "crypto" && (
            <>
              {activateServiceWithCryptoResponse?.can_proceed === "True" && (
                <>
                  {activateServiceWithCryptoResponse?.checkout_session_url
                    ?.status === "success" && (
                    <>
                      <FaCheckCircle size={30} color={"#44bd32"} />
                      <h3>
                        Simply press the ‘Continue’ button below to finalize the
                        activation process.
                      </h3>
                      <a
                        className={styles.activationButton}
                        href={`${activateServiceWithCryptoResponse?.checkout_session_url?.data?.invoice_url}`}
                      >
                        Continue
                      </a>
                    </>
                  )}

                  {(activateServiceWithCryptoResponse?.checkout_session_url
                    ?.status === "error" ||
                    activateServiceWithCryptoStatus === "error") && (
                    <>
                      <RiAlertFill size={30} color={"#e84118"} />
                      <h3>Something went wrong! Please try again later.</h3>
                    </>
                  )}
                </>
              )}

              {(activateServiceWithCryptoResponse?.can_proceed === "False" ||
                activateServiceWithCryptoStatus === "error") && (
                <>
                  <RiAlertFill size={30} color={"#e84118"} />
                  <h3>Something went wrong. Please try again later.</h3>
                </>
              )}
            </>
          )}
          {method === "card" && (
            <>
              {activateServiceWithCardResponse?.can_proceed === "True" ? (
                <>
                  <FaCheckCircle size={30} color={"#44bd32"} />
                  <h3>
                    Finalize the payment process by pressing the button below.
                  </h3>
                  <a
                    className={styles.activationButton}
                    href={`${activateServiceWithCardResponse?.checkout_session_url}`}
                  >
                    Continue
                  </a>
                </>
              ) : (
                <>
                  <RiAlertFill size={30} color={"#e84118"} />
                  <h3>Something went wrong. Please try again later.</h3>
                </>
              )}

              {activateServiceWithCardStatus === "error" && (
                <>
                  <RiAlertFill size={30} color={"#e84118"} />
                  <h3>Something went wrong. Please try again later.</h3>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default DisplayActivationWithPaymentMethodsResponse;
