import React, { useState } from "react";
import { Menu, MenuItem, ProSidebar } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { FaAngleLeft, FaAngleRight, FaBars } from "react-icons/fa";
import styles from "../../styles/Components/sidebar.module.sass";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import insightIcon from "../../asset/Icons/icon-12.svg";
import alertIcon from "../../asset/Icons/icon-16.svg";
import dashboardIcon from "../../asset/Icons/icon-02.svg";
import AIIcon from "../../asset/Icons/icon-15.svg";
import integrationIcon from "../../asset/Icons/integration.svg";
import botIcon from "../../asset/Icons/bot-config.svg";
import { MdMoreVert } from "react-icons/md";
import SideBarMoreMenu from "./SideBarMoreMenu";

const SideBar = ({ insightLayout }) => {
  const [collapse, setCollapse] = useState(false);
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const toggleCollapse = () => {
    setCollapse(!collapse);
  };
  //logout handler
  const location = useLocation();
  const pathName = location?.pathname?.split("/")[1];

  const onCloseMoreMenu = () => {
    setShowMoreMenu(false);
  };

  return (
    <ProSidebar
      collapsed={collapse}
      className={`${styles.sidebar} ${
        insightLayout ? styles.insightSideBar : ""
      }`}
    >
      <Menu iconShape="circle" className={styles.sidebarMenu}>
        {!insightLayout && (
          <MenuItem
            icon={<FaBars className={styles.icon} />}
            onClick={toggleCollapse}
            className={styles.toggleBtn}
          >
            Crypto crispy
          </MenuItem>
        )}
        <MenuItem
          className={pathName === "dashboard" ? styles.active : ""}
          icon={<img src={dashboardIcon} alt={"dashboard"} />}
        >
          {/*<Link to={"/dashboard"} />*/}
          <Link to={"/dashboard"} />
          Dashboard
        </MenuItem>
        <MenuItem
          className={`${pathName === "ai-assistant" ? styles.active : ""}`}
          icon={<img src={AIIcon} alt="ai assistant" />}
        >
          <Link to={"/ai-assistant/assistant"} />
          AI Assistant
        </MenuItem>
        <MenuItem
          icon={
            <img
              style={{ width: "35px", height: "35px" }}
              src={insightIcon}
              alt="Insights"
            />
          }
          className={`${pathName === "insight" ? styles.active : ""}`}
        >
          <Link to={"/insight"} />
          Insights
        </MenuItem>
        <MenuItem
          icon={
            <img
              style={{ width: "35px", height: "35px" }}
              src={botIcon}
              alt="Bot and Automation"
            />
          }
          className={`${pathName === "bot-automation" ? styles.active : ""}`}
        >
          <Link to={"/bot-automation"} />
          Bot & Automation
        </MenuItem>
        <MenuItem
          icon={<img src={integrationIcon} alt="integration" />}
          className={`${pathName === "integration" ? styles.active : ""}`}
        >
          <Link to={"/integration"} />
          Integration
        </MenuItem>
        <MenuItem
          icon={<img src={alertIcon} alt={"alert system"} />}
          className={`${pathName === "alert-system" ? styles.active : ""}`}
        >
          <Link to={"/alert-system"} />
          Alert System
        </MenuItem>
        <MenuItem
          style={{ color: "#666666" }}
          icon={<MdMoreVert />}
          onClick={() => setShowMoreMenu((prevState) => !prevState)}
        >
          More
          {showMoreMenu ? (
            <FaAngleLeft
              style={{ marginLeft: ".5rem", transform: "translateY(.2rem)" }}
            />
          ) : (
            <FaAngleRight
              style={{ marginLeft: ".5rem", transform: "translateY(.2rem)" }}
            />
          )}
        </MenuItem>
      </Menu>
      <SideBarMoreMenu
        className={`${showMoreMenu ? styles.showMore : styles.hideMore}`}
        closeMenu={onCloseMoreMenu}
      />
    </ProSidebar>
  );
};

export default SideBar;
