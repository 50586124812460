import styles from "../styles/ui/ActionButtonsContainer.module.css";

function ActionButtonsContainer({ className, style, children }) {
  return (
    <ul
      className={`${styles.container} ${className || ""}`}
      style={style || {}}
    >
      {children}
    </ul>
  );
}

export default ActionButtonsContainer;
