import { useState } from "react";
import { message } from "antd";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import alert from "../components/alerts/Alert";
import { getProfileData } from "../redux/profile/actions";

const useGeoLocation = () => {
  const {
    main: { userData },
  } = useSelector((state) => state);

  const [location, setLocation] = useState({ lat: null, lon: null });
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const getLocation = () => {
    setLoading(true);
    message.loading("Checking your location...");
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ lat: latitude, lon: longitude });

          await axios
            .post(
              "https://jvp2fozucc.execute-api.us-east-1.amazonaws.com/dev/api",
              {
                platform_mode: "profile",
                request_type: "verify_user_location",
                email: userData.email,
                user: userData?.["custom:custom_username"],
                lat: latitude,
                lon: longitude,
              },
            )
            .then((response) => {
              console.log(response);
              dispatch(
                getProfileData(
                  userData?.email,
                  userData?.["custom:custom_username"],
                ),
              );

              alert("", response?.data?.message, "success", {
                cancel: "Close",
              });
            })
            .catch((error) => {
              console.log(error);
              alert(null, error?.response?.data?.message, "error", error, {
                cancel: "Close",
              });
            })
            .finally(() => setLoading(false));
        },
        (error) => {
          message.error(error.message);
          console.warn("Falling back to IP-based geolocation.");
          setLoading(false);
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 },
      );
    } else {
      message.info("Geolocation is not supported by this browser.");
      setLoading(false);
    }
  };

  return { location, loading, getLocation };
};

export default useGeoLocation;
