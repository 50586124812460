import { Radio } from "antd";
import styles from "../../styles/shared/service-activation/ServiceActivationMethodsForLowCredit.module.css";
import { SiBitcoinsv } from "react-icons/si";
import { BsCreditCard } from "react-icons/bs";
import { AiOutlineDollarCircle } from "react-icons/ai";
import React from "react";
import { useSelector } from "react-redux";
import { servicesCost } from "../../utility/service-activation";

function ServiceActivationMethodsForLowCredit({ onChange, method, service }) {
  const {
    profile: { profileData },
    pricingPlan: { pricingPlan },
  } = useSelector((state) => state);

  const cost = pricingPlan[service] || servicesCost[service];
  const credit = profileData?.credit / 100;
  const isCreditEnoughForOneWeek = credit >= cost / 4;
  const maximumDuration = Math.trunc((30 * credit) / cost);

  return (
    <Radio.Group onChange={onChange} value={method} className={styles.options}>
      <Radio value="payment">
        <div className={styles.radioContainer}>
          <span className={styles.title}>
            <SiBitcoinsv /> Pay As You Go
          </span>
          <p className={styles.description}>
            Pay with Crypto or Credit Card to activate service.
          </p>
        </div>
      </Radio>

      {maximumDuration >= 6 && (
        <Radio value="lowCredit">
          <div className={styles.radioContainer}>
            <span className={styles.title}>
              <BsCreditCard /> Account Credit
            </span>
            <p className={styles.description}>
              Use account credit to activate service for {maximumDuration} days.
            </p>
          </div>
        </Radio>
      )}

      <Radio value="subscribe">
        <div className={styles.radioContainer}>
          <span className={styles.title}>
            <AiOutlineDollarCircle /> Subscription
          </span>
          <p className={styles.description}>
            Get a discount when subscribing with a credit card.
          </p>
        </div>
      </Radio>
    </Radio.Group>
  );
}

export default ServiceActivationMethodsForLowCredit;
