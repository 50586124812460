import { Modal, Spin } from "antd";
import { useSelector } from "react-redux";
import styles from "../../../styles/Components/new-insight/create-strategy/CreateStrategyModal.module.css";
import CreateStrategy from "../create-strategy/CreateStrategy";
import StrategyContextProvider from "../create-strategy/useCreateStrategyContext";
import { ShowCreateStrategyContext } from "../create-strategy/CreateStrategyModal";
import React from "react";

function EditStrategyModal({ show, setShow, data }) {
  const {
    newInsightState: {
      categoriesFeatureTree,
      getInitialDataLoading,
      editStrategyStatus,
    },
  } = useSelector((state) => state);

  function closeModal() {
    if (editStrategyStatus === "loading") return;
    setShow(false);
  }

  return (
    <ShowCreateStrategyContext.Provider value={closeModal}>
      <Modal
        centered
        open={show}
        onCancel={closeModal}
        footer={null}
        width={800}
        className={styles.modal}
      >
        {getInitialDataLoading &&
        Object.keys(categoriesFeatureTree).length === 0 ? (
          <div className={styles.loader}>
            <Spin tip={"Fetching features list..."} />
          </div>
        ) : (
          <StrategyContextProvider>
            <CreateStrategy action="edit" data={data} />
          </StrategyContextProvider>
        )}
      </Modal>
    </ShowCreateStrategyContext.Provider>
  );
}

export default EditStrategyModal;
